<!--
保健病历
该组件只供个人信息模块使用，watch 中监听了 userid 和 tabname 的改变，改变后要做出一些反应
-->
<template>
  <div>
    <div>
      <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      <el-button @click="getHealthCareCases" size="small">刷新</el-button>
    </div>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" class="margin-t-10"
      style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="记录时间" prop="time"></el-table-column>
      <el-table-column label="内容" prop="content"></el-table-column>
      <el-table-column label="标题" prop="remark"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm title="确定删除吗？" @confirm="handleDel(row.id)">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" @current-change="getHealthCareCases">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="closeDialog"
      width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="保健时间" prop="time">
          <el-date-picker v-model="form.time" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"
            class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input v-model="form.content" type="textarea" :autosize="{minRows: 2}"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getHealthCareCasesAPI,
    saveHealthCareCaseAPI,
    delHealthCareCaseAPI,
  } from '@/api/user-record/health-care-cases.js'

  export default {
    name: 'HealthCareCases',
    props: {
      userId: Number,
      tabName: String,
    },
    data() {
      return {
        queryForm: {
          page: 1,
          page_size: 10,
          member_id: ''
        },

        dialogTitle: '',
        showDialog: false,
        submitting: false,
        form: {
          member_id: '',
          time: '',
          content: '',
          remark: '',
        },
        rules: {
          time: [{
            required: true,
            message: '保健时间不能为空',
            trigger: 'change'
          }],
          content: [{
            required: true,
            message: '内容不能为空',
            trigger: 'blur'
          }]
        },

        tableData: [],
        total: 0,
        tableLoading: false,
        curRowId: '',
      }
    },
    watch: {
      // 如果父级 userId 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      userId: {
        immediate: true,
        handler() {
          if (this.tabName === 'HealthCareCases') {
            this.queryForm.member_id = this.userId
            this.form.member_id = this.userId

            this.getHealthCareCases()
          }
        }
      },
      // 如果父级 tabName 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      tabName(newVal) {
        if (newVal === 'HealthCareCases') {
          this.queryForm.member_id = this.userId
          this.form.member_id = this.userId
          this.getHealthCareCases()
        }
      }
    },
    methods: {
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowId = row.id
        const copyRow = JSON.parse(JSON.stringify(row))
        // 回显
        for (let key in this.form) {
          this.form[key] = copyRow[key]
        }
      },
      handleDel(id) {
        delHealthCareCaseAPI({
          id,
        }).then(res => {
          this.$message.success(res.msg)
          this.getHealthCareCases()
        })
      },
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = Object.assign({}, this.form)

          if (this.dialogTitle == '编辑') copyForm.id = this.curRowId

          saveHealthCareCaseAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getHealthCareCases()
          }).finally(() => this.submitting = false)
        })
      },

      getHealthCareCases() {
        this.tableLoading = true

        getHealthCareCasesAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.tableLoading = false
        })
      },
    }
  }
</script>

<style>
</style>