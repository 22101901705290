<!-- 亲属管理的弹窗 -->
<template>
  <el-dialog title="亲属管理" :visible.sync="relationshowDialog" :close-on-click-modal="false" @close="close" width="900px">
    <el-form ref="queryForm" :inline="true" :model="queryForm" size="small">
      <el-form-item label="关键字" prop="keyword">
        <el-input v-model="queryForm.keyword" clearable placeholder="姓名/身份证/手机号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleAdd">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="姓名" prop="realname"></el-table-column>
      <el-table-column label="照片" v-slot="{ row }" width="130px">
        <el-image :src="row.photo" style="width: 100px;">
          <div slot="error" style="color: #606266;">未上传相片</div>
        </el-image>
      </el-table-column>
      <el-table-column label="性别" v-slot="{ row }">{{ sexDict[row.sex].label }}</el-table-column>
      <el-table-column label="年龄" prop="age"></el-table-column>
      <el-table-column label="与老干部关系" prop="urgent_relation"></el-table-column>
      <el-table-column label="状态" v-slot="{ row }">
        <el-tag v-if="row.status === 1" type="success" size="small">正在陪护</el-tag>
        <el-tag v-else type="info" size="small">停止陪护</el-tag>
      </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template v-slot="{ row }">
          <el-link @click="handleView(row)" type="primary" class="margin-r-10">查看</el-link>
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getRelationData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" append-to-body
      @close="closeDialog" width="700px">
      <el-form ref="form" :model="form" :rules="rules" size="small" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="realname">
              <el-input v-model="form.realname" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="form.mobile" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证类型" prop="id_type">
              <el-select v-model="form.id_type" placeholder="请选择" clearable class="el-input_inner--rewrite">
                <el-option v-for="item in idTypeDict" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号" prop="id_no">
              <el-input v-model="form.id_no" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="照片" prop="photo">
              <el-upload ref="photoUpload" :action="UPLOAD_URL" :file-list="photoFileList" list-type="picture-card"
                :limit="1" :before-upload="handleBeforeUpload" :on-success="handlePhotoSuccess"
                :on-remove="handlePhotoRemove" :on-exceed="handlePhotoExceed">
                <i class="el-icon-plus"></i>
                <div slot="tip">只能上传jpg/png文件</div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生日" prop="birthday">
              <el-date-picker v-model="form.birthday" @change="handleBirthdayChange" type="date" placeholder="选择日期"
                value-format="yyyy-MM-dd" class="el-input_inner--rewrite">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄" prop="age">
              <el-input v-model="form.age" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="form.sex">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="与老干部关系" prop="urgent_relation">
              <el-input v-model="form.urgent_relation" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="陪护状态" prop="status">
              <el-radio-group v-model="form.status">
                <el-radio :label="1">正在陪护</el-radio>
                <el-radio :label="0">停止陪护</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号" prop="account">
              <el-input v-model="form.account" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="密码" prop="pwd">
              <el-input v-model="form.pwd" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>

    <!-- 查看的弹窗 -->
    <el-dialog title="查看亲属" :visible.sync="showPreviewDialog" append-to-body width="600px">
      <el-descriptions v-if="curRowData !== null" :column="2" border>
        <el-descriptions-item label="姓名">{{ curRowData.realname }}</el-descriptions-item>
        <el-descriptions-item label="照片">
          <el-image :src="curRowData.photo" style="width: 100px;">
            <div slot="error" style="color: #606266;">未上传相片</div>
          </el-image>
        </el-descriptions-item>
        <el-descriptions-item label="性别">{{ sexDict[curRowData.sex].label }}</el-descriptions-item>
        <el-descriptions-item label="与老干部关系">{{ curRowData.urgent_relation }}
        </el-descriptions-item>
        <el-descriptions-item label="手机号">{{ curRowData.mobile }}</el-descriptions-item>
        <el-descriptions-item label="年龄">{{ curRowData.age }}</el-descriptions-item>
        <el-descriptions-item label="证件类型">
          {{ curRowData.id_type ? idTypeDict[curRowData.id_type].label : '' }}
        </el-descriptions-item>
        <el-descriptions-item label="证件号">{{ curRowData.id_no }}</el-descriptions-item>
        <el-descriptions-item label="生日">{{ curRowData.birthday }}</el-descriptions-item>
        <el-descriptions-item label="陪护状态">
          <el-tag v-if="curRowData.status === 1" type="success" size="small">正在陪护</el-tag>
          <el-tag v-else type="info" size="small">停止陪护</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer">
        <el-button @click="showPreviewDialog = false">关闭</el-button>
      </span>
    </el-dialog>
    <span slot="footer">
      <el-button @click="close">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    saveRelationAPI,
    getRelationListAPI
  } from '@/api/user-record/record.js'

  import {
    UPLOAD_URL,
  } from '@/utils/constant.js'

  import {
    sexDict,
    idTypeDict,
  } from '@/utils/dict.js'

  export default {
    name: 'RealtionDialog',
    data() {
      return {
        sexDict,
        idTypeDict,

        UPLOAD_URL,
        photoFileList: [],

        // 当前弹窗的显示开关
        relationshowDialog: false,

        // 头部搜索相关
        queryForm: {
          page: 1,
          keyword: '',
          bed_number: ''
        },
        // 表格
        tableData: [],
        total: 0,
        pageSize: 0,
        tableLoading: false,

        // 查看相关
        curRowData: null,
        showPreviewDialog: false,

        // 表单相关
        showDialog: false,
        dialogTitle: '',
        submitting: false,
        form: {
          bed_number: '',
          realname: '',
          photo: '',
          id_no: '',
          id_type: 1,
          mobile: '',
          birthday: '',
          sex: 1,
          age: '',
          urgent_relation: '',
          status: 1,
          account: '',
          pwd: '',
        },
        rules: {
          realname: [{
            required: true,
            message: '姓名不能为空',
            trigger: 'blur'
          }],
          mobile: [{
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          }, {
            pattern: /^1[3-9]\d{9}$/,
            message: '手机号格式不正确',
            trigger: 'blur'
          }],
          sex: [{
            required: true,
            message: '性别不能为空',
            trigger: 'change'
          }],
          urgent_relation: [{
            required: true,
            message: '与老干部关系不能为空',
            trigger: 'change'
          }],
          status: [{
            required: true,
            message: '陪护状态不能为空',
            trigger: 'change'
          }],
          account: [{
            required: true,
            message: '账号不能为空',
            trigger: 'blur'
          }],
        }
      }
    },
    methods: {
      // 组件弹出
      open(bedNumber) {
        this.queryForm.bed_number = bedNumber
        this.relationshowDialog = true
        this.getRelationData()
      },
      // 组件关闭
      close() {
        this.relationshowDialog = false
      },

      // 新增按钮
      handleAdd() {
        this.dialogTitle = '新增'
        this.showDialog = true
      },
      // 搜索按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getRelationData()
      },
      // 查看按钮
      handleView(row) {
        this.showPreviewDialog = true
        this.curRowData = row
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        // 设置 form
        if (this.curRowData.photo) {
          this.photoFileList = [{
            url: this.curRowData.photo
          }]
        }
        for (let key in this.form) {
          if (key !== 'pwd') {
            this.form[key] = this.curRowData[key]
          }
        }
      },
      // 关闭弹窗
      closeDialog() {
        this.$refs.photoUpload.clearFiles()
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 照片上传之前
      handleBeforeUpload(file) {
        if (file.type.indexOf('png') < 0 && file.type.indexOf('jpeg') < 0) {
          this.$message.error('照片格式错误！')
          return false
        }
      },
      // 照片上传成功
      handlePhotoSuccess(res, file, fileList) {
        if (res.code === 0) this.photoFileList = fileList
        else this.$message.error('照片上传失败！')
      },
      // 文件数量超出限制
      handlePhotoExceed() {
        this.$message.warning('请删掉原有头像后再进行尝试')
      },
      // 照片移除
      handlePhotoRemove() {
        this.photoFileList = []
      },
      // 生日改变
      handleBirthdayChange(birthday) {
        if (birthday) {
          const birthdayTime = new Date(birthday).getTime()
          const nowTime = new Date().getTime()
          const ONE_YEAR_TIME = 31536000000
          this.form.age = parseInt((nowTime - birthdayTime) / ONE_YEAR_TIME)
        }
      },
      // 提交按钮
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true

          // 设置 form.photo
          // 情况一：新上传的头像
          if (this.photoFileList.length && this.photoFileList[0].response) {
            this.form.photo = this.photoFileList[0].response.data.url
          }
          // 情况二：头像为空（情况三是头像未修改，不做处理）
          if (!this.photoFileList.length) {
            this.form.photo = ''
          }
          // 设置密码
          // if (!this.form.pwd) {
          // 	this.form.pwd = this.curRowData.pwd
          // }
          const copyForm = JSON.parse(JSON.stringify(this.form))
          copyForm.bed_number = this.queryForm.bed_number

          if (this.dialogTitle === '编辑') copyForm.id = this.curRowData.id
          // 提交
          saveRelationAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getRelationData()
          }).finally(() => this.submitting = false)
        })
      },
      // 获取亲属数据
      getRelationData() {
        this.tableLoading = true

        getRelationListAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      },
    }
  }
</script>

<style>
</style>