<template>
  <el-dialog :title="formDialogTitle" :visible.sync="formDialog" width="800px" top="1vh" :close-on-click-modal="false"
    @close="close">
    <el-form ref="recordForm" :model="form" :rules="rules" size="mini" label-width="130px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="类型" prop="occupancy_type">
            <el-select v-model="form.occupancy_type" placeholder="请选择" class="el-input_inner--rewrite">
              <el-option label="老干部" :value="1"></el-option>
              <el-option label="阿姨" :value="3"></el-option>
              <el-option label="其他" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="姓名" prop="realname">
            <el-input v-model="form.realname" class="el-input_inner--rewrite"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="form.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证类型" prop="id_type">
            <el-select v-model="form.id_type" placeholder="请选择" clearable class="el-input_inner--rewrite">
              <el-option v-for="item in idTypeDict" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证号" prop="id_no">
            <el-input v-model="form.id_no" class="el-input_inner--rewrite"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="生日" prop="birthday">
            <el-date-picker v-model="form.birthday" @change="handleBirthdayChange" type="date" placeholder="选择日期"
              value-format="yyyy-MM-dd" class="el-input_inner--rewrite">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="年龄" prop="age">
            <el-input v-model="form.age" class="el-input_inner--rewrite"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="form.mobile" class="el-input_inner--rewrite">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="居住状态" prop="occupancy_status">
            <el-radio-group v-model="form.occupancy_status">
              <el-radio :label="1">未入住</el-radio>
              <el-radio :label="2">已入住</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="房间" prop="room_id">
            <el-cascader v-model="houseArr_form" :options="houseCascaderData" :props="cascaderProps" clearable
              @change="handleCascaderChange_form" class="el-input_inner--rewrite"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="照片" prop="photo">
            <el-upload ref="photoUpload" :action="UPLOAD_URL" :file-list="photoFileList" list-type="picture-card"
              :limit="1" :before-upload="handleBeforeUpload" :on-success="handlePhotoSuccess"
              :on-remove="handlePhotoRemove" :on-exceed="handlePhotoExceed">
              <i class="el-icon-plus"></i>
              <div slot="tip">只能上传jpg/png文件</div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="紧急联系人" prop="emergency">
                <el-input v-model="form.emergency" class="el-input_inner--rewrite"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="与老干部关系" prop="emergency_relation">
                <el-input v-model="form.emergency_relation" class="el-input_inner--rewrite"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="紧急联系人电话" prop="emergency_tel">
                <el-input v-model="form.emergency_tel" class="el-input_inner--rewrite"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="紧急联系人2" prop="emergency">
                <el-input v-model="form.emergency2" class="el-input_inner--rewrite"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="与老干部关系" prop="emergency_relation">
                <el-input v-model="form.emergency2_relation" class="el-input_inner--rewrite"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="紧急联系人2_电话" prop="emergency_tel">
                <el-input v-model="form.emergency2_tel" class="el-input_inner--rewrite"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-form-item label="餐饮卡号" prop="repast_no">
            <el-input v-model="form.repast_no" class="el-input_inner--rewrite"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="会员卡" prop="club_card">
            <el-input v-model="form.club_card" class="el-input_inner--rewrite"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="详情" prop="content">
            <el-input v-model="form.content" type="textarea" style="width: 100%;"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="账号" prop="account">
            <el-input v-model="form.account" class="el-input_inner--rewrite"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="密码" prop="pwd">
            <el-input v-model="form.pwd" class="el-input_inner--rewrite"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否展示" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="关注等级" prop="is_attention">
            <div class="flex">
              <el-rate v-model="form.is_attention" :max="3" style="margin-top: 5px;"></el-rate>
              <el-button @click="form.is_attention = 0" size="mini">重置</el-button>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="handleSubmitRecord" :loading="submitting" type="primary">提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    UPLOAD_URL,
  } from '@/utils/constant.js'
  import {
    idTypeDict,
  } from '@/utils/dict.js'
  import {
    saveUserRecordAPI,
  } from '@/api/user-record/record.js'

  export default {
    data() {
      const mobileRule = {
        pattern: /^1[3-9]\d{9}$/,
        message: '手机号格式不正确',
        trigger: 'blur'
      }

      return {
        // 字典和上传文件的路径
        UPLOAD_URL,
        // 身份证类型字典
        idTypeDict,
        // 该变量用来控制表单提交按钮的可用状态，有多个弹窗使用它，因为目前一次只会存在一个弹窗，所以就通用了
        submitting: false,
        curRecordId: '',

        // 照片相关
        photoDialog: false,
        photoFileList: [],

        // 表单相关
        formDialog: false,
        formDialogTitle: '',
        form: {
          realname: '',
          sex: 1,
          id_type: 1,
          id_no: '',
          birthday: '',
          age: '',
          mobile: '',
          occupancy_status: 2,
          occupancy_type: 1, // 1 老干部 2 其他 3 阿姨
          photo: '',
          is_attention: 1,
          repast_no: '',
          club_card: '',
          content: '',
          account: '',
          pwd: '',
          status: 1,
          room_id: '',
          emergency: '',
          emergency_relation: '',
          emergency_tel: ''
        },
        rules: {
          realname: [{
            required: true,
            message: '姓名不能为空',
            trigger: 'blur'
          }],
          id_no: [{
            required: true,
            message: '身份证号不能为空',
            trigger: 'blur'
          }],
          sex: [{
            required: true,
            message: '性别不能为空',
            trigger: 'change'
          }],
          id_type: [{
            required: true,
            message: '身份证类型不能为空',
            trigger: 'change'
          }],
          mobile: [mobileRule],
          birthday: [{
            required: true,
            message: '生日不能为空',
            trigger: 'change'
          }],
          age: [{
            required: true,
            message: '年龄不能为空',
            trigger: 'blur'
          }],
          occupancy_status: [{
            required: true,
            message: '居住状态不能为空',
            trigger: 'change'
          }],
          status: [{
            required: true,
            message: '是否展示不能为空',
            trigger: 'change'
          }],
          account: [{
            required: true,
            message: '账号不能为空',
            trigger: 'blur'
          }],
          room_id: [{
            required: true,
            message: '房间号不能为空',
            trigger: 'blur'
          }],
          emergency_tel: [mobileRule]
        },

        // 房间联级数据
        houseCascaderData: [],
        // 数据结构
        cascaderProps: {
          label: 'name',
          value: 'id'
        },
        // 选中的每一项id
        houseArr_form: [],
      }
    },
    methods: {
      /**
       * @param {Number} data.userInfo = 用户信息（编辑时必传）
       * @param {Array} data.houseData = 房间数据（必传）
       **/
      open(data) {
        this.houseCascaderData = data.houseData
        this.formDialog = true

        if (data.userInfo === undefined) {
          this.formDialogTitle = '新增'
        } else {
          this.formDialogTitle = '编辑'
          this.curRecordId = data.userInfo.id
          const copyCurRecord = JSON.parse(JSON.stringify(data.userInfo))

          if (copyCurRecord.room) {
            this.houseArr_form = [copyCurRecord.room.floor.building.area.id, copyCurRecord.room.floor.building.id,
              copyCurRecord.room.floor.id, copyCurRecord.room.id
            ]
          } else {
            this.houseArr_form = [];
          }
          // 设置form
          // 照片回显
          if (copyCurRecord.photo) {
            this.photoFileList = [{
              url: copyCurRecord.photo
            }]
          }
          // 回显表单
          for (let key in this.form) {
            // 除密码以外，其他字段全部回显
            if (key !== 'pwd') {
              this.form[key] = copyCurRecord[key]
            }
          }
        }
      },
      close() {
        this.$refs.photoUpload.clearFiles()
        this.$refs.recordForm.resetFields()
        this.houseArr_form = [];
        this.formDialog = false
      },
      // 照片上传之前
      handleBeforeUpload(file) {
        if (file.type.indexOf('png') < 0 && file.type.indexOf('jpeg') < 0) {
          this.$message.error('照片格式错误！')
          return false
        }
      },
      // 照片上传成功
      handlePhotoSuccess(res, file, fileList) {
        if (res.code === 0) this.photoFileList = fileList
        else this.$message.error('照片上传失败！')
      },
      // 文件数量超出限制
      handlePhotoExceed() {
        this.$message.warning('请删掉原有头像后再进行尝试')
      },
      // 照片移除
      handlePhotoRemove() {
        this.photoFileList = []
      },
      // 生日改变
      handleBirthdayChange(birthday) {
        if (birthday) {
          const birthdayTime = new Date(birthday).getTime()
          const nowTime = new Date().getTime()
          const ONE_YEAR_TIME = 31536000000
          this.form.age = parseInt((nowTime - birthdayTime) / ONE_YEAR_TIME)
        }
      },
      // 档案提交按钮
      handleSubmitRecord() {
        this.$refs.recordForm.validate(isValid => {
          if (!isValid) return this.$message.warning('有必填项未填写')

          this.submitting = true

          // 设置 form.photo
          // 情况一：新上传的头像
          if (this.photoFileList.length && this.photoFileList[0].response) {
            this.form.photo = this.photoFileList[0].response.data.url
          }
          // 情况二：头像为空（情况三是头像未修改，不做处理）
          if (!this.photoFileList.length) {
            this.form.photo = ''
          }

          const copyForm = JSON.parse(JSON.stringify(this.form))

          if (this.formDialogTitle === '编辑') copyForm.id = this.curRecordId
          // 提交
          saveUserRecordAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            // 通知父级
            this.$emit('save-success')
            this.close()
          }).finally(() => this.submitting = false)
        })
      },
      // 搜索表单的级联改变 --设置 form.room_id
      handleCascaderChange_form(e) {
        if (e.length > 3) this.form.room_id = e[e.length - 1]
        else this.form.room_id = ''
      },
    }
  }
</script>

<style>
</style>