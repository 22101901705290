<!-- 
 住院登记
 该页面有两种加载模式：
 1、获取全部数据
 2、根据老人id，获取指定老人的数据
 该页面在个人信息模块作为子组件的时候会使用第二种模式
 -->
<template>
  <div>
    <el-form v-if="!userId" ref="inHospitalQuery" :inline="true" :model="inHospitalQuery" size="small">
      <el-form-item label="姓名" prop="realname">
        <el-input v-model="inHospitalQuery.realname"></el-input>
      </el-form-item>
      <el-form-item label="住院日期" prop="date_range">
        <el-date-picker v-model="inHospitalQuery.date_range" type="daterange" value-format="yyyy-MM-dd"
          start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="医院" prop="hospital">
        <el-input v-model="inHospitalQuery.hospital"></el-input>
      </el-form-item>
      <el-form-item label="住院状态" prop="status">
        <el-select v-model="inHospitalQuery.status" placeholder="请选择">
          <el-option v-for="dict in inHospitalStatusDict" :key="dict.value" :label="dict.label"
            :value="dict.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="记录人" prop="adminer">
        <el-input v-model="inHospitalQuery.adminer"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleReset" type="infor">重置</el-button>
        <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      </el-form-item>
    </el-form>
    <!-- 界面第二种模式时使用 -->
    <div v-else style="margin-bottom: 10px;">
      <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      <el-button @click="getInHospitalData" size="small">刷新</el-button>
    </div>

    <el-table :data="inHospitalData" v-loading="inHospitalLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column prop="member.realname" label="姓名"></el-table-column>
      <el-table-column prop="hospitalized_time" label="住院日期"></el-table-column>
      <el-table-column prop="discharge_time" label="出院日期"></el-table-column>
      <el-table-column prop="hospital" label="医院"></el-table-column>
      <el-table-column prop="department" label="科室"></el-table-column>
      <el-table-column prop="number" label="床号"></el-table-column>
      <el-table-column label="住院状态" v-slot="{ row }">{{ inHospitalStatusDict[row.status].label }}
      </el-table-column>
      <el-table-column prop="adminer" label="记录人"></el-table-column>
      <el-table-column label="操作" fixed="right">
        <template v-slot="{ row }">
          <el-link @click="handleView(row)" type="primary" class="margin-r-10">查看</el-link>
          <el-link @click="handleEdit(row)" type="primary">编辑</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="inHopitalTotal"
        :current-page.sync="inHospitalQuery.page" :page-size="inHopitalPageSize" @current-change="getInHospitalData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑用户住院信息的弹窗 -->
    <el-dialog :title="inHospitalDialogTitle" :visible.sync="formDialog" :close-on-click-modal="false"
      @close="closeDialog" width="700px">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col v-if="!userId" :span="12">
            <el-form-item label="选择老干部" prop="member_id">
              <el-select v-model="form.member_id" clearable filterable remote :remote-method="getRecordData"
                :loading="recordLoading" placeholder="输入姓名进行查找" class="el-input_inner--rewrite">
                <el-option v-for="record in recordData" :key="record.id" :label="record.realname" :value="record.id">
                  <div class="flex justify-content-s-b">
                    <span>{{ record.realname }}</span><span>{{ record.birthday }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="医院名称" prop="hospital">
              <el-input v-model="form.hospital" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="科室" prop="department">
              <el-input v-model="form.department" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="床号" prop="number">
              <el-input v-model="form.number" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="住院时间" prop="hospitalized_time">
              <el-date-picker v-model="form.hospitalized_time" value-format="yyyy-MM-dd" placeholder="请选择"
                class="el-input_inner--rewrite"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出院时间" prop="discharge_time">
              <el-date-picker v-model="form.discharge_time" value-format="yyyy-MM-dd" placeholder="请选择"
                class="el-input_inner--rewrite"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="住院原因" prop="cause">
              <el-input v-model="form.cause" type="textarea" autosize placeholder="可换行输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="出院诊断" prop="discharge">
              <el-input v-model="form.discharge" type="textarea" autosize placeholder="可换行输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="新增诊断" prop="disease">
              <el-input v-model="form.disease" type="textarea" autosize placeholder="可换行输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="记录人" prop="user_id">
              <el-select v-model="form.user_id" clearable remote class="el-input_inner--rewrite">
                <el-option v-for="user in userData" :key="user.id" :label="user.name" :value="user.id">
                  <div class="flex justify-content-s-b">
                    <span>{{ user.name }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="form.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="formSubmitting" @click="handleSubmitInHospital" type="primary">提交</el-button>
      </span>
    </el-dialog>

    <!-- 查看住院详情的弹窗 -->
    <el-dialog title="查看住院详情" :visible.sync="viewInHospitalInfoDialog" width="500px">
      <el-descriptions v-if="curRowData !== null" :column="1" border>
        <el-descriptions-item label="姓名">{{ curRowData.member.realname }}</el-descriptions-item>
        <el-descriptions-item label="住院日期">{{ curRowData.hospitalized_time }}</el-descriptions-item>
        <el-descriptions-item label="出院日期">{{ curRowData.discharge_time || '' }}</el-descriptions-item>
        <el-descriptions-item label="医院">{{ curRowData.hospital }}</el-descriptions-item>
        <el-descriptions-item label="科室">{{ curRowData.department }}</el-descriptions-item>
        <el-descriptions-item label="床号">{{ curRowData.number }}</el-descriptions-item>
        <el-descriptions-item label="住院状态">{{ inHospitalStatusDict[curRowData.status].label }}
        </el-descriptions-item>
        <el-descriptions-item label="新增诊断">
          <el-input :value="curRowData.disease" type="textarea" autosize resize="none" disabled
            class="textarea"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="住院原因">
          <el-input :value="curRowData.cause" type="textarea" autosize resize="none" disabled
            class="textarea"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="出院诊断">
          <el-input :value="curRowData.discharge" type="textarea" autosize resize="none" disabled
            class="textarea"></el-input>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer">
        <el-button @click="viewInHospitalInfoDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getUserRecordDataAPI
  } from '@/api/user-record/record.js'
  import {
    saveInHospitalDataAPI,
    getInHospitalDataAPI,
  } from '@/api/in-hospital.js'

  import {
    inHospitalStatusDict
  } from '@/utils/dict.js'

  import {
    getUsersAPI,
  } from '@/api/duty.js'
  export default {
    name: 'InHospital',
    props: {
      userId: Number,
      tabName: String,
    },
    data() {
      return {
        inHospitalStatusDict,

        // 头部搜索相关
        inHospitalQuery: {
          page: 1,
          status: '',
          realname: '',
          date_range: '',
          hospital: '',
          adminer: '',
          member_id: ''
        },
        inHospitalDate: [],
        // 表格
        inHospitalData: [],
        inHopitalTotal: 0,
        inHopitalPageSize: 0,
        inHospitalLoading: false,

        // 查看相关
        curRowData: null,
        viewInHospitalInfoDialog: false,

        // 档案数据
        recordData: [],
        recordLoading: false,

        // 表单相关
        formDialog: false,
        inHospitalDialogTitle: '',
        formSubmitting: false,
        form: {
          member_id: '',
          hospital: '',
          department: '',
          number: '',
          status: '',
          hospitalized_time: '',
          discharge_time: '',
          disease: '',
          remark: '',
          user_id: '', // 记录人
          cause: '',
          discharge: ''
        },
        rules: {
          member_id: [{
            required: true,
            message: '姓名不能为空',
            trigger: 'change'
          }],
          hospital: [{
            required: true,
            message: '医院不能为空',
            trigger: 'blur'
          }],
          department: [{
            required: true,
            message: '科室不能为空',
            trigger: 'blur'
          }],
          hospitalized_time: [{
            required: true,
            message: '住院时间不能为空',
            trigger: 'blur'
          }],
          number: [{
            required: true,
            message: '床号不能为空',
            trigger: 'blur'
          }],
        },
        // 记录人数据
        userData: [],
      }
    },
    watch: {
      // 如果父级 userId 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      userId: {
        immediate: true,
        handler() {
          if (this.tabName === 'InHospital') {
            this.inHospitalQuery.member_id = this.userId
            this.form.member_id = this.userId

            this.getInHospitalData()
          }
        }
      },
      // 如果父级 tabName 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      tabName(newVal) {
        if (newVal === 'InHospital') {
          this.inHospitalQuery.member_id = this.userId
          this.form.member_id = this.userId
          this.getInHospitalData()
        }
      }
    },
    created() {
      if (!this.userId) {
        this.getRecordData()
        this.getInHospitalData()
      }
      this.getUsers()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.inHospitalDialogTitle = '新增'
        this.formDialog = true
      },
      // 重置按钮
      handleReset() {
        this.$refs.inHospitalQuery.resetFields()
      },
      // 搜索按钮
      handleSearch() {
        this.inHospitalQuery.page = 1
        this.getInHospitalData()
      },
      // 查看按钮
      handleView(row) {
        this.viewInHospitalInfoDialog = true
        this.curRowData = row
      },
      // 编辑按钮
      handleEdit(row) {
        this.formDialog = true
        this.inHospitalDialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        // 回显老人
        this.recordData = [this.curRowData.member]
        // 设置 form
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
      },
      // 关闭住院信息填写的弹窗
      closeDialog() {
        this.formDialog = false
        this.$refs.form.resetFields()
      },
      // 住院信息填写弹窗的提交按钮
      handleSubmitInHospital() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.formSubmitting = true
          const copyForm = JSON.parse(JSON.stringify(this.form))

          if (this.inHospitalDialogTitle === '编辑') copyForm.id = this.curRowData.id
          // 提交
          saveInHospitalDataAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getInHospitalData()
          }).finally(() => this.formSubmitting = false)
        })
      },
      // 获取档案数据
      getRecordData(keyword) {
        this.recordLoading = true

        setTimeout(() => {
          getUserRecordDataAPI({
            page: 1,
            keyword,
          }).then(res => {
            this.recordData = res.data
            this.recordLoading = false
          })
        }, 300)
      },
      // 获取住院数据
      getInHospitalData() {
        this.inHospitalLoading = true

        getInHospitalDataAPI(this.inHospitalQuery).then(res => {
          this.inHospitalData = res.data
          this.inHopitalTotal = res.total
          this.inHopitalPageSize = res.per_page
          this.inHospitalLoading = false
        })
      },
      // 获取记录人列表
      getUsers() {
        getUsersAPI().then(res => {
          this.userData = res.data;
        })
      },
    }
  }
</script>

<style scoped>
  .textarea /deep/ .el-textarea__inner {
    border: 0;
    cursor: default;
    background-color: #FFF;
    color: #606266;
    padding: 0;
  }
</style>