<template>
	<div class="flex">
		<!-- 左侧用户列表 -->
		<LeftUserList ref="leftUserList" :house-data="houseCascaderData" @user-select="handleUserSelect" class="page-left">
		</LeftUserList>

		<!-- 右侧组件 -->
		<div class="page-right">
			<template v-if="curUserId !== ''">
				<!-- 用户基础信息 -->
				<UserBaseInfo :user-id="curUserId" :tab-name="curTabName" :house-data="houseCascaderData"
					@user-update="handleUserUpdate"></UserBaseInfo>

				<div class="margin-t-10">
					<el-radio-group v-model="curTabName" size="small">
						<el-radio-button v-for="item in tabs" :key="item.comName"
							:label="item.comName">{{ item.text }}</el-radio-button>
					</el-radio-group>
				</div>

				<!-- 以下组件需要监听user id 和 tab name 的变化 -->
				<div class="margin-t-10">
					<!-- 住院登记 -->
					<div v-show="curTabName == 'InHospital'">
						<InHospital :user-id="curUserId" :tab-name="curTabName"></InHospital>
					</div>
					<!-- 照护服务 -->
					<div v-show="curTabName == 'Care'">
						<Care :user-id="curUserId" :tab-name="curTabName"></Care>
					</div>
					<!-- 体检记录 -->
					<div v-show="curTabName == 'ExaminationRecord'">
						<ExaminationRecord :user-id="curUserId" :tab-name="curTabName"></ExaminationRecord>
					</div>
					<!-- 就诊记录 -->
					<div v-show="curTabName == 'SeeDoctor'">
						<SeeDoctor :user-id="curUserId" :tab-name="curTabName"></SeeDoctor>
					</div>
					<!-- 健康巡诊 -->
					<div v-show="curTabName == 'Patrol'">
						<Patrol :user-id="curUserId" :tab-name="curTabName"></Patrol>
					</div>
					<!-- 病史 -->
					<div v-show="curTabName == 'MedicalHistory'">
						<MedicalHistory :user-id="curUserId" :tab-name="curTabName"></MedicalHistory>
					</div>
					<!-- 长期用药 -->
					<div v-show="curTabName == 'Medication'">
						<Medication :user-id="curUserId" :tab-name="curTabName"></Medication>
					</div>
					<!-- 疫苗接种情况 -->
					<div v-show="curTabName == 'PersonalVaccine'">
						<PersonalVaccine :user-id="curUserId" :tab-name="curTabName"></PersonalVaccine>
					</div>
					<!-- 保健病历 -->
					<div v-show="curTabName == 'HealthCareCases'">
						<HealthCareCases :user-id="curUserId" :tab-name="curTabName"></HealthCareCases>
					</div>
				</div>
			</template>
			<el-empty v-else description="未选择老干部"></el-empty>
		</div>
	</div>
</template>

<script>
	import LeftUserList from './components/LeftUserList.vue'
	import UserBaseInfo from './components/UserBaseInfo.vue'
	import HealthCareCases from './components/HealthCareCases.vue'
	import InHospital from '../in-hospital/index.vue'
	import Care from '../care/index.vue'
	import ExaminationRecord from '../examination-record/index.vue'
	import SeeDoctor from '../see-doctor/index.vue'
	import Patrol from '../patrol/index.vue'
	import MedicalHistory from '../medical-history/index.vue'
	import Medication from './components/Medication.vue'
	import PersonalVaccine from './components/PersonalVaccine.vue'

	import {
		getHouseCascaderDataAPI,
	} from '@/api/base-data.js'

	export default {
		name: 'UserRecordManagement',
		components: {
			LeftUserList,
			UserBaseInfo,
			HealthCareCases,
			InHospital,
			Care,
			ExaminationRecord,
			SeeDoctor,
			Patrol,
			MedicalHistory,
			Medication,
			PersonalVaccine,
		},
		data() {
			return {
				// 当前用户 id、name
				curUserId: '',
				curUserName: '',
				// 房间联级数据
				houseCascaderData: [],
				// tab 数据
				curTabName: '',
				tabs: [{
					text: '住院登记',
					comName: 'InHospital'
				}, {
					text: '照护服务',
					comName: 'Care'
				}, {
					text: '体检记录',
					comName: 'ExaminationRecord'
				}, {
					text: '就诊管理',
					comName: 'SeeDoctor'
				}, {
					text: '健康巡诊',
					comName: 'Patrol'
				}, {
					text: '病史',
					comName: 'MedicalHistory'
				}, {
					text: '长期用药',
					comName: 'Medication'
				}, {
					text: '疫苗接种情况',
					comName: 'PersonalVaccine'
				}, {
					text: '保健病历',
					comName: 'HealthCareCases'
				}],
			}
		},
		created() {
			this.getHouseCascaderData()
		},
		methods: {
			// 用户选择事件
			handleUserSelect(userId, userName) {
				if (this.curUserId === userId) return
				// 设置用户 id
				this.curUserId = userId
				this.curUserName = userName
			},
			// UserBaseInfo 组件中修改了用户信息
			handleUserUpdate() {
				this.$refs.leftUserList.getRecordData()
			},
			// 获取级联数据（获取到房间），子组件需要用
			getHouseCascaderData() {
				getHouseCascaderDataAPI({
					level: 3
				}).then(res => {
					this.houseCascaderData = res.data
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.page-left {
		margin-right: 10px;
		min-width: 265px;
		max-width: 265px;
		height: 84vh;
		overflow: hidden;
	}

	.page-right {
		flex: 1;
		min-width: 800px;
		height: 84vh;
		padding: 0 10px;
		overflow-y: auto;
	}
</style>