<!-- 
 健康巡诊
 该页面有两种加载模式：
 1、获取全部数据
 2、根据老人id，获取指定老人的数据
 该页面在个人信息模块作为子组件的时候会使用第二种模式
 -->
<template>
  <div>
    <el-form v-if="!userId" ref="queryForm" :inline="true" :model="queryForm" size="small">
      <el-form-item label="姓名" prop="member_keyword">
        <el-input v-model="queryForm.member_keyword"></el-input>
      </el-form-item>
      <el-form-item label="巡诊时间">
        <el-date-picker v-model="dateRange" @change="handleDateRangeChange" type="daterange" value-format="yyyy-MM-dd"
          start-placeholder="开始日期" end-placeholder="结束日期" style="width: 260px;"></el-date-picker>
      </el-form-item>
      <el-form-item label="记录人" prop="user_keyword">
        <el-input v-model="queryForm.user_keyword"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleReset" type="infor">重置</el-button>
        <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      </el-form-item>
    </el-form>
    <!-- 界面第二种模式时使用 -->
    <div v-else style="margin-bottom: 10px;">
      <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      <el-button @click="getPatrolData" size="small">刷新</el-button>
    </div>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60px"></el-table-column>
      <el-table-column label="巡诊时间" prop="record_time" width="140px"></el-table-column>
      <el-table-column label="姓名" prop="member_info.realname" width="120px"></el-table-column>
      <el-table-column label="服药情况" prop="medicine_state" width="120px"></el-table-column>
      <el-table-column label="饮食情况" prop="diet_state" width="120px"></el-table-column>
      <el-table-column label="心理及精神情况" prop="psychology_state" width="120px"></el-table-column>
      <el-table-column label="大便情况" prop="shit_state" width="120px"></el-table-column>
      <el-table-column label="小便情况" prop="pee_state" width="120px"></el-table-column>
      <el-table-column label="其他情况" prop="other" width="300px"></el-table-column>
      <el-table-column label="记录人" width="120px" v-slot="{ row }">
        <span v-if="row.user_info">{{ row.user_info.name }}</span>
      </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template v-slot="{ row }">
          <el-link @click="handleView(row)" type="primary" class="margin-r-10">查看</el-link>
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm @confirm="handleDel(row.id)" title="确定删除吗？">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getPatrolData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="closeDialog"
      width="750px">
      <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
        <el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="巡诊时间" prop="record_time">
                <el-date-picker v-model="form.record_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间" class="el-input_inner--rewrite">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col v-if="!userId" :span="12">
              <el-form-item label="选择老干部" prop="member_id">
                <el-select v-model="form.member_id" clearable filterable remote :remote-method="getRecordData"
                  :loading="recordLoading" placeholder="输入姓名进行查找" class="el-input_inner--rewrite">
                  <el-option v-for="record in recordData" :key="record.id" :label="record.realname" :value="record.id">
                    <div class="flex justify-content-s-b">
                      <span>{{ record.realname }}</span><span>{{ record.birthday }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="服药情况" prop="medicine_state">
                <el-autocomplete v-model="form.medicine_state" :fetch-suggestions="getInputSuggestions"
                  class="el-input_inner--rewrite"></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col v-show="form.medicine_state == '异常'" :span="12">
              <el-form-item label="描述" prop="medicine">
                <el-input v-model="form.medicine" type="textarea" autosize placeholder="服药情况"
                  class="el-input_inner--rewrite"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="饮食情况" prop="diet_state">
                <el-autocomplete v-model="form.diet_state" :fetch-suggestions="getInputSuggestions"
                  class="el-input_inner--rewrite"></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col v-show="form.diet_state == '异常'" :span="12">
              <el-form-item label="描述" prop="diet">
                <el-input v-model="form.diet" type="textarea" autosize placeholder="饮食情况"
                  class="el-input_inner--rewrite"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="心理及精神情况" prop="psychology_state">
                <el-autocomplete v-model="form.psychology_state" :fetch-suggestions="getInputSuggestions"
                  class="el-input_inner--rewrite"></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col v-show="form.psychology_state == '异常'" :span="12">
              <el-form-item label="描述" prop="psychology">
                <el-input v-model="form.psychology" type="textarea" autosize placeholder="心理及精神情况"
                  class="el-input_inner--rewrite"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="大便情况" prop="shit_state">
                <el-autocomplete v-model="form.shit_state" :fetch-suggestions="getInputSuggestions"
                  class="el-input_inner--rewrite"></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col v-show="form.shit_state == '异常'" :span="12">
              <el-form-item label="描述" prop="shit">
                <el-input v-model="form.shit" type="textarea" autosize placeholder="大便情况"
                  class="el-input_inner--rewrite"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="小便情况" prop="pee_state">
                <el-autocomplete v-model="form.pee_state" :fetch-suggestions="getInputSuggestions"
                  class="el-input_inner--rewrite"></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col v-show="form.pee_state == '异常'" :span="12">
              <el-form-item label="描述" prop="pee">
                <el-input v-model="form.pee" type="textarea" autosize placeholder="小便情况"
                  class="el-input_inner--rewrite"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-col :span="12">
            <el-form-item label="收缩压" prop="sp">
              <el-input v-model="form.sp">
                <template slot="append">(mmHg)</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="舒张压" prop="dp">
              <el-input v-model="form.dp">
                <template slot="append">(mmHg)</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="脉率" prop="pr">
              <el-input v-model="form.pr">
                <template slot="append">(次/分钟)</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="呼吸" prop="breath">
              <el-input v-model="form.breath">
                <template slot="append">(次/分钟)</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="血氧" prop="spo2h">
              <el-input v-model="form.spo2h">
                <template slot="append">(%)</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="血糖" prop="blood">
              <el-input v-model="form.blood">
                <template slot="append">(mmol/L)</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="其他情况" prop="other">
              <el-input v-model="form.other" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="formSubmitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>

    <!-- 查看的弹窗 -->
    <el-dialog title="查看就诊信息" :visible.sync="showPreviewDialog" width="750px">
      <el-descriptions v-if="curRowData !== null" :column="2" border :labelStyle="{width: '150px'}"
        :contentStyle="{width: '180px'}">
        <el-descriptions-item label="巡诊时间">{{ curRowData.record_time }}</el-descriptions-item>
        <el-descriptions-item label="姓名">{{ curRowData.member_info.realname }}</el-descriptions-item>
        <el-descriptions-item label="服药情况">{{ curRowData.medicine_state }}</el-descriptions-item>
        <el-descriptions-item label="描述">{{ curRowData.medicine }}</el-descriptions-item>
        <el-descriptions-item label="饮食情况">{{ curRowData.diet_state }}</el-descriptions-item>
        <el-descriptions-item label="描述">{{ curRowData.diet }}</el-descriptions-item>
        <el-descriptions-item label="心理及精神情况">{{ curRowData.psychology_state }}</el-descriptions-item>
        <el-descriptions-item label="描述">{{ curRowData.psychology }}</el-descriptions-item>
        <el-descriptions-item label="大便情况">{{ curRowData.shit_state }}</el-descriptions-item>
        <el-descriptions-item label="描述">{{ curRowData.shit }}</el-descriptions-item>
        <el-descriptions-item label="小便情况">{{ curRowData.pee_state }}</el-descriptions-item>
        <el-descriptions-item label="描述">{{ curRowData.pee }}</el-descriptions-item>
        <el-descriptions-item label="其他情况">{{ curRowData.other }}</el-descriptions-item>
        <el-descriptions-item label="收缩压(mmHg)">{{ curRowData.sp }}</el-descriptions-item>
        <el-descriptions-item label="舒张压(mmHg)">{{ curRowData.dp }}</el-descriptions-item>
        <el-descriptions-item label="脉率(次/分钟)">{{ curRowData.pr }}</el-descriptions-item>
        <el-descriptions-item label="呼吸(次/分钟)">{{ curRowData.breath }}</el-descriptions-item>
        <el-descriptions-item label="血氧(%)">{{ curRowData.spo2h }}</el-descriptions-item>
        <el-descriptions-item label="血糖(mmol/L)">{{ curRowData.blood }}</el-descriptions-item>
        <el-descriptions-item
          label="记录人">{{ curRowData.user_info ? curRowData.user_info.name : '' }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer">
        <el-button @click="showPreviewDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getUserRecordDataAPI
  } from '@/api/user-record/record.js'
  import {
    savePatrolDataAPI,
    getPatrolDataAPI,
    delPatrolDataAPI,
  } from '@/api/patrol.js'

  export default {
    name: 'Patrol',
    props: {
      userId: Number,
      tabName: String,
    },
    data() {
      return {
        // 头部搜索相关
        dateRange: [],
        queryForm: {
          page: 1,
          member_keyword: '',
          user_keyword: '',
          start: '',
          end: '',
          member_id: ''
        },
        // 表格
        tableData: [],
        total: 0,
        pageSize: 0,
        tableLoading: false,

        // 查看相关
        curRowData: null,
        showPreviewDialog: false,

        // 档案数据
        recordData: [],
        recordLoading: false,

        // 表单相关
        showDialog: false,
        dialogTitle: '',
        formSubmitting: false,
        form: {
          record_time: '',
          member_id: '',
          sp: '',
          dp: '',
          pr: '',
          breath: '',
          spo2h: '',
          blood: '',
          medicine_state: '',
          medicine: '',
          diet_state: '',
          diet: '',
          psychology_state: '',
          psychology: '',
          shit_state: '',
          shit: '',
          pee_state: '',
          pee: '',
          other: '',
          label: '',
          user_id: ''
        },
        rules: {
          member_id: [{
            required: true,
            message: '姓名不能为空',
            trigger: 'change'
          }],
          record_time: [{
            required: true,
            message: '巡诊时间不能为空',
            trigger: 'change'
          }],
        }
      }
    },
    watch: {
      // 如果父级 userId 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      userId: {
        immediate: true,
        handler() {
          if (this.tabName === 'Patrol') {
            this.queryForm.member_id = this.userId
            this.form.member_id = this.userId

            this.getPatrolData()
          }
        }
      },
      // 如果父级 tabName 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      tabName(newVal) {
        if (newVal === 'Patrol') {
          this.queryForm.member_id = this.userId
          this.form.member_id = this.userId
          this.getPatrolData()
        }
      }
    },
    created() {
      if (!this.userId) {
        this.getRecordData()
        this.getPatrolData()
      }
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.dialogTitle = '新增'
        this.showDialog = true
        // 如果是第二种加载模式，新增时获取巡诊记录数据中第一条数据回显到表单上供修改
        if (this.userId) {
          if (!this.tableData.length) return

          let firstData = this.tableData[0]

          this.$nextTick(() => {
            for (let key in this.form) {
              if (key == 'record_time') continue
              
              this.form[key] = firstData[key]
            }
          })
        }
      },
      // 重置按钮
      handleReset() {
        this.dateRange = []
        this.$refs.queryForm.resetFields()
      },
      // 搜索按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getPatrolData()
      },
      // 查看按钮
      handleView(row) {
        this.showPreviewDialog = true
        this.curRowData = row
      },
      // 删除按钮
      handleDel(id) {
        delPatrolDataAPI({
          id,
        }).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getPatrolData()
          }
        })
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        // 回显老人
        this.recordData = [this.curRowData.member_info]
        // 设置 form
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
      },
      handleDateRangeChange(e) {
        this.queryForm.start = e[0]
        this.queryForm.end = e[1]
      },
      // 关闭弹窗
      closeDialog() {
        this.showDialog = false
        this.$refs.form.resetFields()
      },
      // 提交按钮
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.clearDesc()
          this.formSubmitting = true
          const copyForm = JSON.parse(JSON.stringify(this.form))
          copyForm.user_id = sessionStorage.getItem('systemUserId')

          if (this.dialogTitle === '编辑') copyForm.id = this.curRowData.id
          // 提交
          savePatrolDataAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getPatrolData()
          }).finally(() => this.formSubmitting = false)
        })
      },
      // 清除描述，如果情况不等于异常，则清空异常描述字段
      clearDesc() {
        if (this.form.medicine_state != '异常') this.form.medicine = ''
        if (this.form.diet_state != '异常') this.form.diet = ''
        if (this.form.psychology_state != '异常') this.form.psychology = ''
        if (this.form.shit_state != '异常') this.form.shit = ''
        if (this.pee_state != '异常') this.form.pee = ''
      },
      // 获取xx情况的输入建议
      getInputSuggestions(str, cb) {
        cb([{
          value: '正常'
        }, {
          value: '异常'
        }])
      },
      // 获取档案数据
      getRecordData(keyword) {
        this.recordLoading = true

        setTimeout(() => {
          getUserRecordDataAPI({
            page: 1,
            keyword,
          }).then(res => {
            this.recordData = res.data
            this.recordLoading = false
          })
        }, 300)
      },
      // 获取就诊数据
      getPatrolData() {
        this.tableLoading = true

        getPatrolDataAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      },
    }
  }
</script>

<style scoped>
  .flex-r-c {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flex-r-c span {
    padding-bottom: 18px;
    white-space: nowrap;
  }
</style>