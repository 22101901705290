// 长期用药
import request from '@/utils/util.js'

// 列表
export function getMedicationsAPI(data) {
	return request.get('/medication/record_list', data)
}

// 新增、编辑
export function saveMedicationAPI(data) {
	return request.post('/medication/record_save', data)
}

// 删除
export function delMedicationAPI(data) {
	return request.post('/medication/record_delete', data)
}

// 导入
export function importMedicationAPI(formData) {
	return request.post('/medication/record_upload', formData)
}