// 住院登记
import request from '@/utils/util.js'

// 获取住院数据
export function getInHospitalDataAPI(data) {
	return request.get('/hospitalized/record_list', data)
}

// 新增、编辑住院信息
export function saveInHospitalDataAPI(data) {
	return request.post('/hospitalized/record_save', data)
}