// 就诊管理
import request from '@/utils/util.js'

// 新增、编辑
export function saveSeeDoctorAPI(data) {
	return request.post('/health_diagnose/save', data)
}

// 列表
export function getSeeDoctorDataAPI(data) {
	return request.get('/health_diagnose/list', data)
}

// 删除
export function delSeeDoctorDataAPI(data) {
	return request.post('/health_diagnose/delete', data)
}
