<!-- 
 照护服务
 该页面有两种加载模式：
 1、获取全部数据
 2、根据老人id，获取指定老人的数据
 该页面在个人信息模块作为子组件的时候会使用第二种模式
 -->
<template>
  <div>
    <el-form v-if="!userId" ref="queryForm" :inline="true" :model="queryForm" size="small">
      <el-form-item label="姓名" prop="member_keyword">
        <el-input v-model="queryForm.member_keyword" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="记录时间" prop="dateRange">
        <el-date-picker v-model="dateRange" @change="handleRecordDateChange" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>
    <!-- 界面第二种模式时使用 -->
    <div v-else style="margin-bottom: 10px;">
      <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      <el-button @click="getCareData" size="small">刷新</el-button>
    </div>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="姓名" prop="member_info.realname"></el-table-column>
      <el-table-column label="记录时间" prop="record_time"></el-table-column>
      <el-table-column label="记录内容" prop="record_time"></el-table-column>
      <el-table-column label="标记" prop="label"></el-table-column>
      <el-table-column label="照片" v-slot="{ row }">
        <el-image :src="row.photo[0]" :preview-src-list="row.photo" fit="scale-down"
          style="width: 100px; height: 100px">
        </el-image>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleView(row)" type="primary" class="margin-r-10">查看</el-link>
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm @confirm="handleDel(row.id)" title="确定删除吗？">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getCareData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :before-close="closeDialog" :close-on-click-modal="false"
      width="550px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item v-if="!userId" label="姓名" prop="member_id">
          <el-select v-model="form.member_id" filterable clearable remote :loading="recordLoading"
            :remote-method="getRecordData" placeholder="请选择" class="el-input_inner--rewrite">
            <el-option v-for="item in recordData" :key="item.id" :label="item.realname" :value="item.id">
              <div class="flex justify-content-s-b">
                <span>{{ item.realname }}</span><span>{{ item.birthday }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="记录时间" prop="record_time">
          <el-date-picker v-model="form.record_time" type="datetime" placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss" class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="标记" prop="label">
          <el-radio-group v-model="form.label">
            <el-radio label="true"></el-radio>
            <el-radio label="false"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="记录内容" prop="record_content">
          <el-input v-model="form.record_content" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="照片" prop="photo">
          <el-upload :action="UPLOAD_URL" :file-list="photoFileList" :before-upload="handleBeforeUpload"
            :on-success="handlePhotoSuccess" :on-remove="handlePhotoRemove" list-type="picture-card">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>

    <!-- 查看的弹窗 -->
    <el-dialog title="查看记录" :visible.sync="showPreviewDialog" width="500px">
      <el-descriptions v-if="curRowData !== null" :column="1" border>
        <el-descriptions-item label="姓名">{{ curRowData.member_info.realname }}</el-descriptions-item>
        <el-descriptions-item label="记录时间">{{ curRowData.record_time }}</el-descriptions-item>
        <el-descriptions-item label="记录内容">{{ curRowData.record_content }}</el-descriptions-item>
        <el-descriptions-item label="标记">{{ curRowData.label }}</el-descriptions-item>
        <el-descriptions-item label="照片">
          <el-image v-for="(item, index) in curRowData.photo" :key="index" :src="item"
            :preview-src-list="curRowData.photo" fit="scale-down"
            style="width: 100px; height: 100px;margin-right: 5px;">
          </el-image>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer">
        <el-button @click="showPreviewDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    UPLOAD_URL,
  } from '@/utils/constant.js'

  import {
    getUserRecordDataAPI
  } from '@/api/user-record/record.js'
  import {
    addCareDataAPI,
    getCareDataAPI,
    updateCareDataAPI,
    delCareDataAPI,
  } from '@/api/care.js'

  export default {
    name: 'Care',
    props: {
      userId: Number,
      tabName: String,
    },
    data() {
      return {
        // 其他依赖数据
        UPLOAD_URL,
        recordData: [],
        recordLoading: false,
        // 搜索
        dateRange: [],
        queryForm: {
          page: 1,
          member_keyword: '',
          user_keyword: '',
          start: '',
          end: '',
          member_id: ''
        },
        // 表格
        tableData: [],
        total: 0,
        pageSize: 0,
        tableLoading: false,
        curRowData: null,
        // 照片文件列表
        photoFileList: [],
        // 预览
        showPreviewDialog: false,
        // 表单相关
        showDialog: false,
        dialogTitle: '',
        submitting: false,
        form: {
          member_id: '',
          record_time: '',
          record_content: '',
          label: 'true',
          photo: '',
          user_id: ''
        },
        rules: {
          member_id: [{
            required: true,
            message: '姓名不能为空',
            trigger: 'change'
          }],
          record_time: [{
            required: true,
            message: '记录时间不能为空',
            trigger: 'change'
          }],
          record_content: [{
            required: true,
            message: '记录内容不能为空',
            trigger: 'change'
          }],
          photo: [{
            required: true,
            message: '照片不能为空',
            trigger: 'change'
          }],
        }
      }
    },
    watch: {
      // 如果父级 userId 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      userId: {
        immediate: true,
        handler() {
          if (this.tabName === 'Care') {
            this.queryForm.member_id = this.userId
            this.form.member_id = this.userId

            this.getCareData()
          }
        }
      },
      // 如果父级 tabName 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      tabName(newVal) {
        if (newVal === 'Care') {
          this.queryForm.member_id = this.userId
          this.form.member_id = this.userId
          this.getCareData()
        }
      }
    },
    created() {
      if (!this.userId) {
        this.getRecordData()
        this.getCareData()
      }
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      // 搜索按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getCareData()
      },
      // 记录时间选择 --顶部搜索框
      handleRecordDateChange(e) {
        this.queryForm.start = e[0]
        this.queryForm.end = e[1]
      },
      // 重置按钮
      handleReset() {
        this.queryForm.start = ''
        this.queryForm.end = ''
        this.$refs.queryForm.resetFields()
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 删除按钮
      handleDel(id) {
        delCareDataAPI(id).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getCareData()
          }
        })
      },
      // 照片上传之前
      handleBeforeUpload(file) {
        if (file.type.indexOf('png') < 0 && file.type.indexOf('jpeg') < 0) {
          this.$message.error('文件格式错误')
          return false
        }
      },
      // 照片上传成功
      handlePhotoSuccess(res, file, fileList) {
        this.photoFileList = fileList
      },
      // 照片移除
      handlePhotoRemove(file, fileList) {
        this.photoFileList = fileList
      },
      // 查看按钮
      handleView(row) {
        this.showPreviewDialog = true
        this.curRowData = row
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        // 回显 photo
        this.photoFileList = this.curRowData.photo.map(item => {
          return {
            url: item
          }
        })
        // 回显老人
        this.recordData = [this.curRowData.member_info]
        // 设置form
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
      },
      // 提交按钮
      handleSubmit() {
        this.submitting = true
        // 设置 photo
        // 获取 photoFileList 中的 path
        const paths = this.photoFileList.map(item => {
          if (item.response) return item.response.data.url
          else return item.url
        })
        // 转换称 “,” 拼接的
        this.form.photo = paths.toString()
        // 校验表单
        this.$refs.form.validate(isValid => {
          if (!isValid) return this.submitting = false

          this.form.user_id = sessionStorage.getItem('systemUserId')
          // 提交
          if (this.dialogTitle === '新增') {
            addCareDataAPI(this.form).then(res => {
              this.$message.success(res.data)
              this.closeDialog()
              this.getCareData()
            }).finally(() => this.submitting = false)
          } else {
            updateCareDataAPI(this.curRowData.id, this.form).then(res => {
              this.$message.success(res.data)
              this.closeDialog()
              this.getCareData()
            }).finally(() => this.submitting = false)
          }
        })
      },
      // 获取档案数据
      getRecordData(keyword = '') {
        this.recordLoading = true

        setTimeout(() => {
          getUserRecordDataAPI({
            page: 1,
            keyword,
          }).then(res => {
            this.recordData = res.data
            this.recordLoading = false
          })
        }, 300)
      },
      // 获取照护数据
      getCareData() {
        this.tableLoading = true

        getCareDataAPI(this.queryForm).then(res => {
          this.tableData = res.data.map(item => {
            const isSingle = item.photo.indexOf(',') < 0

            if (isSingle) item.photo = [item.photo]
            else item.photo = item.photo.split(',')
            return item
          })
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      },
    }
  }
</script>

<style scoped>
</style>