// 病史
import request from '@/utils/util.js'

// 列表
export function getMedicalHistorysAPI(data) {
	return request.get('/disease/record_list', data)
}

// 新增、编辑
export function saveMedicalHistoryAPI(data) {
	return request.post('/disease/record_save', data)
}

// 删除
export function delMedicalHistoryAPI(data) {
	return request.post('/disease/record_delete', data)
}

// 导入
export function importMedicalHistoryAPI(formData) {
	return request.post('/disease/record_upload', formData)
}