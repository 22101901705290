<!-- 
 体检记录
 该页面有两种加载模式：
 1、获取全部数据
 2、根据老人id，获取指定老人的数据
 该页面在个人信息模块作为子组件的时候会使用第二种模式
 -->
<template>
  <div>
    <el-form v-if="!userId" ref="queryForm" :inline="true" :model="queryForm" size="small">
      <el-form-item label="姓名" prop="member_keyword">
        <el-input v-model="queryForm.member_keyword"></el-input>
      </el-form-item>
      <el-form-item label="记录时间">
        <el-date-picker v-model="dateRange" @change="handleDateRangeChange" type="daterange" value-format="yyyy-MM-dd"
          start-placeholder="开始日期" end-placeholder="结束日期" style="width: 260px;"></el-date-picker>
      </el-form-item>
      <el-form-item label="记录人" prop="user_keyword">
        <el-input v-model="queryForm.user_keyword"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleReset" type="infor">重置</el-button>
        <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      </el-form-item>
    </el-form>
    <!-- 界面第二种模式时使用 -->
    <div v-else style="margin-bottom: 10px;">
      <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      <el-button @click="getExaminationData" size="small">刷新</el-button>
    </div>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="550px"
      style="width: 100%;">
      <el-table-column label="#" type="index" width="60px"></el-table-column>
      <el-table-column label="姓名" prop="member_info.realname" width="80px"></el-table-column>
      <el-table-column label="体检时间" prop="record_time" width="140px"></el-table-column>
      <el-table-column label="体检医院" prop="hospital" width="140px"></el-table-column>
      <el-table-column label="体检小结" prop="peroration" width="300px"></el-table-column>
      <el-table-column label="专家意见" prop="opinion" width="300px"></el-table-column>
      <el-table-column label="门诊处置" prop="dispose" width="200px"></el-table-column>
      <el-table-column label="记录人" prop="user_info.name"></el-table-column>
      <el-table-column label="操作" fixed="right" width="100px">
        <template v-slot="{ row }">
          <el-link @click="handleView(row)" type="primary" class="margin-r-10">查看详情</el-link>
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm @confirm="handleDel(row.id)" title="确定删除吗？">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getExaminationData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="closeDialog"
      width="700px">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col v-if="!userId" :span="12">
            <el-form-item label="选择老干部" prop="member_id">
              <el-select v-model="form.member_id" clearable filterable remote :remote-method="getRecordData"
                :loading="recordLoading" placeholder="输入姓名进行查找" class="el-input_inner--rewrite">
                <el-option v-for="record in recordData" :key="record.id" :label="record.realname" :value="record.id">
                  <div class="flex justify-content-s-b">
                    <span>{{ record.realname }}</span><span>{{ record.birthday }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="体检时间" prop="record_time">
              <el-date-picker v-model="form.record_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间" class="el-input_inner--rewrite">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="体检医院" prop="hospital">
              <el-input v-model="form.hospital" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="体检小结" prop="peroration">
              <el-input v-model="form.peroration" autosize type="textarea" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="专家意见" prop="opinion">
              <el-input v-model="form.opinion" autosize type="textarea" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="门诊处置" prop="dispose">
              <el-input v-model="form.dispose" autosize type="textarea" class="el-input_inner--rewrite"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="formSubmitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>

    <!-- 查看的弹窗 -->
    <el-dialog title="查看健康数据" :visible.sync="showPreviewDialog" width="700px">
      <el-descriptions v-if="curRowData !== null" :column="1" border :labelStyle="{width: '100px'}">
        <el-descriptions-item label="姓名">{{ curRowData.member_info.realname }}</el-descriptions-item>
        <el-descriptions-item label="体检时间">{{ curRowData.record_time }}</el-descriptions-item>
        <el-descriptions-item label="体检医院">{{ curRowData.hospital }}</el-descriptions-item>
        <el-descriptions-item label="体检小结">{{ curRowData.peroration }}</el-descriptions-item>
        <el-descriptions-item label="专家意见">{{ curRowData.opinion }}</el-descriptions-item>
        <el-descriptions-item label="门诊处置">{{ curRowData.dispose }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer">
        <el-button @click="showPreviewDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getUserRecordDataAPI
  } from '@/api/examination-record.js'
  import {
    saveExaminationRecordAPI,
    getExaminationDataAPI,
    delExaminationDataAPI,
  } from '@/api/examination-record'

  export default {
    name: 'ExaminationRecord',
    props: {
      userId: Number,
      tabName: String,
    },
    data() {
      return {
        // 头部搜索相关
        dateRange: [],
        queryForm: {
          page: 1,
          member_keyword: '',
          user_keyword: '',
          start: '',
          end: '',
          member_id: ''
        },
        // 表格
        tableData: [],
        total: 0,
        pageSize: 0,
        tableLoading: false,

        // 查看相关
        curRowData: null,
        showPreviewDialog: false,

        // 档案数据
        recordData: [],
        recordLoading: false,

        // 表单相关
        showDialog: false,
        dialogTitle: '',
        formSubmitting: false,
        form: {
          member_id: '',
          record_time: '',
          hospital: '',
          peroration: '',
          opinion: '',
          dispose: '',
        },
        rules: {
          member_id: [{
            required: true,
            message: '姓名不能为空',
            trigger: 'change'
          }],
          record_time: [{
            required: true,
            message: '体检时间不能为空',
            trigger: 'change'
          }],
          hospital: [{
            required: true,
            message: '体检医院不能为空',
            trigger: 'blur'
          }],
          peroration: [{
            required: true,
            message: '体检小结不能为空',
            trigger: 'blur'
          }],
          opinion: [{
            required: true,
            message: '专家意见不能为空',
            trigger: 'blur'
          }],
          dispose: [{
            required: true,
            message: '门诊处置不能为空',
            trigger: 'blur'
          }],
        }
      }
    },
    watch: {
      // 如果父级 userId 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      userId: {
        immediate: true,
        handler() {
          if (this.tabName === 'ExaminationRecord') {
            this.queryForm.member_id = this.userId
            this.form.member_id = this.userId

            this.getExaminationData()
          }
        }
      },
      // 如果父级 tabName 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      tabName(newVal) {
        if (newVal === 'ExaminationRecord') {
          this.queryForm.member_id = this.userId
          this.form.member_id = this.userId
          this.getExaminationData()
        }
      }
    },
    created() {
      if (!this.userId) {
        this.getExaminationData()
      }
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.dialogTitle = '新增'
        this.showDialog = true
      },
      // 重置按钮
      handleReset() {
        this.dateRange = []
        this.$refs.queryForm.resetFields()
      },
      // 搜索按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getExaminationData()
      },
      // 查看按钮
      handleView(row) {
        this.showPreviewDialog = true
        this.curRowData = row
      },
      // 删除按钮
      handleDel(id) {
        delExaminationDataAPI({
          id,
        }).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getExaminationData()
          }
        })
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        // 回显老人
        this.recordData = [this.curRowData.member_info]
        // 回显form
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
      },
      handleDateRangeChange(e) {
        this.queryForm.start = e[0]
        this.queryForm.end = e[1]
      },
      // 关闭弹窗
      closeDialog() {
        this.showDialog = false
        this.$refs.form.resetFields()
      },
      // 提交按钮
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.formSubmitting = true
          const copyForm = JSON.parse(JSON.stringify(this.form))
          copyForm.user_id = sessionStorage.getItem('systemUserId')

          if (this.dialogTitle === '编辑') copyForm.id = this.curRowData.id
          // 提交
          saveExaminationRecordAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getExaminationData()
          }).finally(() => this.formSubmitting = false)
        })
      },
      // 获取档案数据
      getRecordData(keyword = '') {
        this.recordLoading = true

        setTimeout(() => {
          getUserRecordDataAPI({
            page: 1,
            keyword,
          }).then(res => {
            this.recordData = res.data
            this.recordLoading = false
          })
        }, 300)
      },
      // 获取体检数据
      getExaminationData() {
        this.tableLoading = true

        getExaminationDataAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      },
    }
  }
</script>

<style>
</style>