// 值班管理
import request from '@/utils/util.js'

// 新增、编辑值班
export function saveDutyAPI(data) {
	return request.post('/duty/save', data)
}

// 值班列表
export function getDutyDataAPI(data) {
	return request.get('/duty/list', data)
}

// 删除值班
export function delDutyAPI(data) {
	return request.post('/duty/delete', data)
}

// 角色列表
export function getRolesAPI(data) {
	return request.get('/roles', data)
}

// 用户列表
export function getUsersAPI(data) {
	return request.get('/users', data)
}