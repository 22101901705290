// 保健病历
import request from '@/utils/util.js'

// 列表
export function getHealthCareCasesAPI(data) {
	return request.get('/fitness/record_list', data)
}

// 新增、编辑
export function saveHealthCareCaseAPI(data) {
	return request.post('/fitness/record_save', data)
}

// 删除
export function delHealthCareCaseAPI(data) {
	return request.post('/fitness/record_delete', data)
}