// 公共的
import request from '@/utils/util.js'

// 获取省市区数据
export function getAreaDataAPI(data) {
	return request.get('/cascade', data)
}

// 导入数据
export function importDataAPI(data) {
	return request.post('/import', data)
}

// 导出数据
export function exportDataAPI(data) {
	return request.post('/export', data)
}