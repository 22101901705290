<!-- 
 病史
 该页面有两种加载模式：
 1、获取全部数据
 2、根据老人id，获取指定老人的数据
 该页面在个人信息模块作为子组件的时候会使用第二种模式
 -->
<template>
  <div>
    <el-form v-if="!userId" ref="queryForm" :model="queryForm" :inline="true" size="small">
      <el-form-item label="姓名" prop="realname">
        <el-input v-model="queryForm.realname"></el-input>
      </el-form-item>
      <el-form-item label="就诊时间" prop="date_range">
        <el-date-picker v-model="queryForm.date_range" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 260px;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="记录人" prop="adminer">
        <el-input v-model="queryForm.adminer"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleResetQueryForm">重置</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
        <el-button @click="handleImport" type="primary">导入</el-button>
      </el-form-item>
    </el-form>
    <!-- 界面第二种模式时使用 -->
    <div v-else style="margin-bottom: 10px;">
      <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      <el-button @click="getMedicalHistorys" size="small">刷新</el-button>
    </div>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="姓名" prop="member.realname"></el-table-column>
      <el-table-column label="病名" prop="name"></el-table-column>
      <el-table-column label="患病日期" prop="time"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column label="转归" prop="remove_type"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm title="确定删除吗？" @confirm="handleDel(row.id)">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" @current-change="getMedicalHistorys">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="closeDialog"
      width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item v-if="!userId" label="姓名" prop="member_id">
          <el-select v-model="form.member_id" clearable filterable remote placeholder="输入姓名进行搜索"
            :remote-method="getUserRecords" :loading="userLoadig" class="el-input_inner--rewrite">
            <el-option v-for="item in userRecords" :key="item.id" :label="item.realname" :value="item.id">
              <div class="flex justify-content-s-b">
                <span>{{ item.realname }}</span>
                <span style="color: #8492a6;">{{ item.mobile }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="病名" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="患病日期" prop="time">
          <el-date-picker v-model="form.time" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
            class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="转归" prop="remove_type">
          <el-autocomplete v-model="form.remove_type" :fetch-suggestions="getRemoveTypeSuggestions"
            class="el-input_inner--rewrite">
          </el-autocomplete>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提 交</el-button>
      </span>
    </el-dialog>

    <!-- 导入的弹窗 -->
    <el-dialog title="导入" :visible.sync="showImportDialog" :close-on-click-modal="false" width="500px">
      <el-link
        href="https://huarenoss.oss-cn-beijing.aliyuncs.com/files/template/%E8%80%81%E5%B9%B2%E9%83%A8%E8%BF%87%E5%BE%80%E7%97%85%E5%8F%B2.xlsx"
        type="primary">导入模板的地址，点击下载</el-link>
      <el-upload ref="uploadFile" action="#" :auto-upload="false" :limit="1" :on-change="handleFileChange"
        class="margin-t-20">
        <el-button size="small" type="primary">选择文件</el-button>
      </el-upload>
      <span slot="footer">
        <el-button @click="showImportDialog = false">取 消</el-button>
        <el-button :loading="submitting" @click="handleConfirmImport" type="primary">导 入</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getMedicalHistorysAPI,
    saveMedicalHistoryAPI,
    delMedicalHistoryAPI,
    importMedicalHistoryAPI,
  } from '@/api/medical-history.js'
  import {
    getUserRecordDataAPI
  } from '@/api/user-record/record.js'

  export default {
    name: 'MedicalHistory',
    props: {
      userId: Number,
      tabName: String,
    },
    data() {
      return {
        queryForm: {
          realname: '',
          date_range: '',
          adminer: '',
          page: 1,
          page_size: 10,
          member_id: ''
        },

        dialogTitle: '',
        showDialog: false,
        submitting: false,
        form: {
          member_id: '',
          name: '',
          remark: '',
          time: '',
          remove_type: ''
        },
        rules: {
          member_id: [{
            required: true,
            message: '姓名不能为空',
            trigger: 'change'
          }],
          name: [{
            required: true,
            message: '病名不能为空',
            trigger: 'blur'
          }],
          time: [{
            required: true,
            message: '患病日期不能为空',
            trigger: 'change'
          }],
        },

        tableData: [],
        total: 0,
        tableLoading: false,
        curRowId: '',

        // 用户档案
        userRecords: [],
        userLoadig: false,

        // 导入相关
        showImportDialog: false,
        selectedFile: null
      }
    },
    watch: {
      // 如果父级 userId 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      userId: {
        immediate: true,
        handler() {
          if (this.tabName === 'MedicalHistory') {
            this.queryForm.member_id = this.userId
            this.form.member_id = this.userId

            this.getMedicalHistorys()
          }
        }
      },
      // 如果父级 tabName 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      tabName(newVal) {
        if (newVal === 'MedicalHistory') {
          this.queryForm.member_id = this.userId
          this.form.member_id = this.userId
          this.getMedicalHistorys()
        }
      }
    },
    created() {
      if (!this.userId) {
        this.getMedicalHistorys()
      }
    },
    methods: {
      handleSearch() {
        this.queryForm.page = 1
        this.getMedicalHistorys()
      },
      handleResetQueryForm() {
        this.$refs.queryForm.resetFields()
      },
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      // 导入按钮
      handleImport() {
        this.showImportDialog = true
      },
      handleFileChange(file) {
        this.selectedFile = file
      },
      handleConfirmImport() {
        this.submitting = true

        const formData = new FormData()
        formData.append('import_file', this.selectedFile.raw)

        importMedicalHistoryAPI(formData).then(() => {
          this.$message.success('导入成功')
          this.getMedicalHistorys()
        }).catch(() => {
          this.$message.error('请检查文件内容格式')
        }).finally(() => {
          this.$refs.uploadFile.clearFiles()
          this.submitting = false
        })
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowId = row.id
        const copyRow = JSON.parse(JSON.stringify(row))
        // 回显老人
        this.userRecords = [copyRow.member]
        // 回显
        for (let key in this.form) {
          this.form[key] = copyRow[key]
        }
        // 返回的 member_id 是字符串，导致回显失败
        this.form.member_id = parseInt(this.form.member_id)
      },
      handleDel(id) {
        delMedicalHistoryAPI({
          id,
        }).then(res => {
          this.$message.success(res.msg)
          this.getMedicalHistorys()
        })
      },
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = Object.assign({}, this.form)

          if (this.dialogTitle == '编辑') copyForm.id = this.curRowId

          saveMedicalHistoryAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getMedicalHistorys()
          }).finally(() => this.submitting = false)
        })
      },

      getRemoveTypeSuggestions(str, cb) {
        cb([{
          value: '痊愈'
        }, {
          value: '好转'
        }, {
          value: '稳定'
        }])
      },
      getMedicalHistorys() {
        this.tableLoading = true

        getMedicalHistorysAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.tableLoading = false
        })
      },
      getUserRecords(keyword) {
        this.userLoadig = true

        setTimeout(() => {
          getUserRecordDataAPI({
            keyword,
            page: 1
          }).then(res => {
            this.userRecords = res.data
            this.userLoadig = false
          })
        })
      }
    }
  }
</script>

<style>
</style>