// 健康记录
import request from '@/utils/util.js'

// 新增、编辑
export function saveExaminationRecordAPI(data) {
	return request.post('/health_record/save', data)
}

// 列表
export function getExaminationDataAPI(data) {
	return request.get('/health_record/list', data)
}

// 删除
export function delExaminationDataAPI(data) {
	return request.post('/health_record/delete', data)
}

