<template>
  <div>
    <div class="flex">
      <el-button @click="handleAddRecord" type="primary" icon="el-icon-plus" size="small" class="flex-1">新增
      </el-button>
      <el-button @click="handleImport" type="warning" size="small" icon="el-icon-upload2">导入
      </el-button>
      <el-popconfirm title="导出当前页数据" @confirm="handleExport">
        <el-button slot="reference" type="warning" size="small" icon="el-icon-download"
          style="margin-left: 10px;">导出</el-button>
      </el-popconfirm>
    </div>
    <el-select v-model="recordQuery.occupancy_status" @change="getRecordData" placeholder="入住状态" size="small" clearable
      class="margin-t-10 el-input_inner--rewrite">
      <el-option label="未入住" :value="1"></el-option>
      <el-option label="已入住" :value="2"></el-option>
    </el-select>
    <el-select v-model="recordQuery.occupancy_type" @change="getRecordData" placeholder="类型" size="small"
      class="margin-t-10 el-input_inner--rewrite">
      <el-option label="老干部" :value="1"></el-option>
      <el-option label="阿姨" :value="3"></el-option>
      <el-option label="其他" :value="4"></el-option>
    </el-select>
    <div class="flex margin-t-10">
      <el-input v-model="recordQuery.keyword" placeholder="姓名/首字母/电话/身份证" prefix-icon="el-icon-search" size="small"
        class="flex-1 margin-r-10">
      </el-input>
      <el-tooltip class="item" effect="dark" content="搜索" placement="top-start">
        <el-button @click="getRecordData" circle icon="el-icon-search" size="small"></el-button>
      </el-tooltip>
    </div>
    <div v-loading="recordLoading" class="record-list">
      <div v-for="record in recordData" :key="record.id" @click="handleRecordClick(record)" class="record-list_item">
        <div>
          <div class="flex">
            <span class="margin-r-10">{{ record.realname }}</span>
            <span class="margin-r-10">{{ record.age }}岁</span>
            <span class="margin-r-10">{{ liveStatusDict[record.occupancy_status].label }}</span>
            <span>{{ record.birthday || '' }}</span>
          </div>
          <div class="flex align-items-c margin-t-10">
            <span>关注等级：</span>
            <el-rate v-if="record.is_attention" :value="record.is_attention" disabled
              :max="record.is_attention"></el-rate>
            <span v-else>无</span>
          </div>
        </div>
      </div>
    </div>
    <el-pagination small background layout="total, prev, pager, next" :current-page.sync="recordQuery.page"
      :total="recordTotal" :page-size="recordPageSize" @current-change="getRecordData" class="margin-t-10">
    </el-pagination>

    <UserRecordFormDialog ref="userRecordForm"></UserRecordFormDialog>

    <!-- 导入数据的弹窗 -->
    <el-dialog title="导入档案" :visible.sync="visibleImportDialog" :close-on-click-modal="false"
      @close="visibleImportDialog = false" width="500px">
      <el-link href="https://huarenoss.oss-cn-beijing.aliyuncs.com/files/template/member_import_template.xlsx"
        type="primary">导入模板的地址，点击下载</el-link>
      <el-upload :action="UPLOAD_URL" :file-list="importFileList" :limit="1" :on-success="importSuccess"
        :on-remove="importFileRemove" accept=".xlsx" class="margin-t-20">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <span slot="footer">
        <el-button @click="visibleImportDialog = false">取消</el-button>
        <el-button @click="handleSubmitImport" :loading="submitting" type="primary">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import UserRecordFormDialog from './UserRecordFormDialog.vue'

  import {
    UPLOAD_URL,
  } from '@/utils/constant.js'
  import {
    liveStatusDict,
    sexDict,
  } from '@/utils/dict.js'
  import {
    getUserRecordDataAPI,
  } from '@/api/user-record/record.js'
  import {
    importDataAPI,
    exportDataAPI,
  } from '@/api/common.js'

  export default {
    components: {
      UserRecordFormDialog,
    },
    props: {
      houseData: {
        type: Array,
        default: function() {
          return []
        }
      }
    },
    data() {
      return {
        // 字典和上传文件的路径
        UPLOAD_URL,
        // 居住状态字典
        liveStatusDict,
        // 性别字典
        sexDict,

        // 界面左侧相关数据
        recordQuery: {
          page: 1,
          keyword: '',
          occupancy_status: '',
          occupancy_type: 1, // 1 老干部 2 其他 3 阿姨
        },
        recordLoading: false,
        recordData: [],
        recordTotal: 0,
        recordPageSize: 10,

        // 导入数据的弹窗相关
        visibleImportDialog: false,
        importFileList: [],

        submitting: false
      }
    },
    created() {
      this.getRecordData()
    },
    methods: {
      // 添加老人按钮
      handleAddRecord() {
        this.$refs.userRecordForm.open({
          houseData: this.houseData
        })
      },
      // 导入按钮
      handleImport() {
        this.visibleImportDialog = true
        this.importFileList = []
      },
      // 导出按钮
      handleExport() {
        exportDataAPI({
          type: 201,
          occupancy_status: this.recordQuery.occupancy_status,
          keywords: this.recordQuery.keyword
        }).then(res => {
          if (res.code === 0) {
            this.$alert('导出成功，请前往导出记录界面下载导出的数据', '注意', {
              confirmButtonText: '确定',
            })
          }
        })
      },
      // 用户数据项点击
      handleRecordClick(userInfo) {
        // 需要通知父级
        this.$emit('user-select', userInfo.id, userInfo.realname)
      },
      // 档案文件上传成功
      importSuccess(res, file, fileList) {
        this.importFileList = fileList
      },
      // 删除档案文件
      importFileRemove(file, fileList) {
        this.importFileList = fileList
      },
      // 档案导入的提交按钮
      handleSubmitImport() {
        if (!this.importFileList.length) return this.$message.warning('未上传文件')

        this.submitting = true
        importDataAPI({
          type: 101,
          file: this.importFileList[0].response.data.url
        }).then(res => {
          if (res.code === 0) {
            this.visibleImportDialog = false
            this.$message.success(res.msg)
            this.getRecordData()
          }
        }).finally(() => this.submitting = false)
      },
      // 获取档案数据
      async getRecordData() {
        this.recordLoading = true

        await getUserRecordDataAPI(this.recordQuery).then(res => {
          this.recordData = res.data
          this.recordTotal = res.total
          this.recordPageSize = res.per_page
          this.recordLoading = false
        })
      },
    }
  }
</script>

<style scoped>
  /* 用户列表 */
  .record-list {
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    margin-top: 10px;
    max-height: 55vh;
    font-size: 14px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .record-list_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dcdfe6;
    padding: 10px;
  }

  .record-list_item:nth-child(even) {
    background: #F7F7F7;
  }

  .record-list_item:hover {
    cursor: pointer;
    background: #EBEBEB;
  }

  .record-list_item:last-child {
    border-bottom: none;
  }
</style>