// 健康巡检
import request from '@/utils/util.js'

// 新增、编辑
export function savePatrolDataAPI(data) {
	return request.post('/health_patrol/save', data)
}

// 列表
export function getPatrolDataAPI(data) {
	return request.get('/health_patrol/list', data)
}

// 删除
export function delPatrolDataAPI(data) {
	return request.post('/health_patrol/delete', data)
}
