<template>
	<div>
		<div style="margin-bottom: 10px;">
			<el-button @click="handleAdd" type="primary" size="small">新增</el-button>
			<el-button @click="getMedications" size="small">刷新</el-button>
		</div>

		<el-table :data="tableData" v-loading="tableLoading" border stripe
			:header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px"
			style="width: 100%;">
			<el-table-column label="#" type="index" width="60"></el-table-column>
			<el-table-column label="药物名称" v-slot="{ row }">
				<el-input v-if="row.isEdit" v-model="row.name" placeholder="药物名称" size="small"></el-input>
				<span v-else>{{ row.name }}</span>
			</el-table-column>
			<el-table-column label="剂量" v-slot="{ row }">
				<el-input v-if="row.isEdit" v-model="row.num" placeholder="剂量" size="small"></el-input>
				<span v-else>{{ row.num }}</span>
			</el-table-column>
			<el-table-column label="备注" v-slot="{ row }">
				<el-input v-if="row.isEdit" v-model="row.remark" placeholder="备注" size="small"></el-input>
				<span v-else>{{ row.remark }}</span>
			</el-table-column>
			<el-table-column label="操作">
				<template v-slot="{ row, $index }">
					<el-link v-if="row.isEdit" @click="handleRowSubmit($index)" type="primary" class="margin-r-10">保存</el-link>
					<el-link v-if="row.id && !row.isEdit" @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
					<el-popconfirm title="确定删除吗？" @confirm="handleDel(row, $index)">
						<el-link slot="reference" type="danger">删除</el-link>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<div class="margin-t-10 flex">
			<el-pagination background layout="prev, pager, next, total, jumper" :total="total"
				:current-page.sync="queryForm.page" @current-change="getMedications">
			</el-pagination>
			<el-button size="small">确定</el-button>
		</div>
	</div>
</template>

<script>
	import {
		getMedicationsAPI,
		saveMedicationAPI,
		delMedicationAPI,
	} from '@/api/medication.js'

	export default {
		name: 'Medication',
		props: {
			userId: Number,
			tabName: String,
		},
		data() {
			return {
				queryForm: {
					realname: '',
					date_range: '',
					adminer: '',
					page: 1,
					page_size: 10,
					member_id: ''
				},
				// form 只作为一个格式，或者说 interface
				form: {
					member_id: '',
					name: '',
					remark: '',
					num: '',
				},

				tableData: [],
				total: 0,
				tableLoading: false,
				curRowId: '',
			}
		},
		watch: {
			// 如果父级 userId 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
			userId: {
				immediate: true,
				handler() {
					if (this.tabName === 'Medication') {
						this.queryForm.member_id = this.userId
						this.form.member_id = this.userId

						this.getMedications()
					}
				}
			},
			// 如果父级 tabName 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
			tabName(newVal) {
				if (newVal === 'Medication') {
					this.queryForm.member_id = this.userId
					this.form.member_id = this.userId
					this.getMedications()
				}
			}
		},
		methods: {
			handleAdd() {
				const copyForm = Object.assign({}, this.form)
				copyForm.member_id = this.userId
				copyForm.isEdit = true
				this.tableData.unshift(copyForm)
			},
			// 行内填写提交
			handleRowSubmit(index) {
				const copyRow = Object.assign({}, this.tableData[index])
				// 校验
				for (let key in this.form) {
					if (key == 'remark') continue
					if (!copyRow[key]) {
						this.$message.info('数据填写不完整，请检查（除备注外都是必填项）')
						return
					}
				}
				const copyForm = Object.assign({}, this.form)
				// 复制给 form，form 是最终接口需要的格式
				for (let key in copyForm) {
					copyForm[key] = copyRow[key]
				}
				// 判断是否有 id，没有是新增，有是编辑
				if (copyRow.id) copyForm.id = copyRow.id

				saveMedicationAPI(copyForm).then(res => {
					this.$message.success(res.msg)
					this.getMedications()
				})
			},
			handleEdit(row) {
				row.isEdit = true
			},
			handleDel(row, index) {
				if (row.id) {
					delMedicationAPI({
						id: row.id
					}).then(res => {
						this.$message.success(res.msg)
						this.getMedications()
					})
				} else {
					this.tableData.splice(index, 1)
				}
			},

			getMedications() {
				this.tableLoading = true

				getMedicationsAPI(this.queryForm).then(res => {
					this.tableData = res.data.map(item => {
						// 添加一个字段用来控制是否是编辑
						item.isEdit = false
						return item
					})
					this.total = res.total
					this.tableLoading = false
				})
			},
		}
	}
</script>

<style>
</style>