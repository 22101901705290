// 疫苗相关
import request from '@/utils/util.js'

/**
 * @description 疫苗接种情况
 */
// 列表
export function getVaccinesAPI(data) {
	return request.get('/vaccine/record_list', data)
}

// 新增、编辑
export function saveVaccineAPI(data) {
	return request.post('/vaccine/record_save', data)
}

// 删除
export function delVaccineAPI(data) {
	return request.post('/vaccine/record_delete', data)
}


/**
 * @description 疫苗种类
 */
// 列表
export function getVaccineCategoriesAPI(data) {
	return request.get('/vaccine_type/record_list', data)
}

// 所有
export function getAllVaccineCategoriesAPI(data) {
	return request.get('/vaccine_type/all', data)
}

// 新增、编辑
export function saveVaccineCategoryAPI(data) {
	return request.post('/vaccine_type/record_save', data)
}

// 删除
export function delVaccineCategoryAPI(data) {
	return request.post('/vaccine_type/record_delete', data)
}