<template>
	<div v-if="curRecord !== null">
		<el-card shadow="never">
			<!-- 界面右边头部 -->
			<div slot="header" class="record-card_header">
				<span class="font-b">{{ curRecord.realname }}</span>
				<div class="flex">
					<el-button @click="handleRelationManage" type="primary" size="mini">亲属管理</el-button>
					<el-button @click="handleRecordEdit" type="primary" size="mini">编辑</el-button>
					<el-button @click="handleUpdatePhoto" type="primary" size="mini">修改照片</el-button>
					<el-button @click="handleUpdateRepast" type="primary" size="mini">设置餐饮号</el-button>
				</div>
			</div>
			<!-- 用户详情 -->
			<div class="record-desc">
				<div class="record-desc_left">
					<el-image :src="curRecord.photo" style="width: 100px;">
						<div slot="error" style="color: #606266;">未上传相片</div>
					</el-image>
				</div>
				<div class="record-desc_right">
					<el-descriptions :column="3" border>
						<el-descriptions-item label="姓名">{{ curRecord.realname }}</el-descriptions-item>
						<el-descriptions-item label="身份证">{{ curRecord.id_no }}</el-descriptions-item>
						<el-descriptions-item label="手机">{{ curRecord.mobile }}</el-descriptions-item>
						<el-descriptions-item label="生日">{{ curRecord.birthday }}</el-descriptions-item>
						<el-descriptions-item label="性别">{{ sexDict[curRecord.sex].label }}
						</el-descriptions-item>
						<el-descriptions-item label="状态">{{ liveStatusDict[curRecord.occupancy_status].label }}
						</el-descriptions-item>
						<el-descriptions-item label="餐饮卡号">{{ curRecord.repast_no }}</el-descriptions-item>
						<el-descriptions-item v-if="curRecord.room" label="房间" :span="2">
							{{ curRecord.room.floor.building.area.name ? curRecord.room.floor.building.area.name : '' }}/
							{{ curRecord.room.floor.building.name ? curRecord.room.floor.building.name : '' }}/
							{{ curRecord.room.floor.name ? curRecord.room.floor.name : '' }}/
							{{ curRecord.room.name ? curRecord.room.name : '' }}
						</el-descriptions-item>
						<el-descriptions-item v-else label="房间" :span="2">未设置房间号</el-descriptions-item>
						<el-descriptions-item label="关注等级" :span="2">
							<el-rate v-if="curRecord.is_attention" :value="curRecord.is_attention" disabled
								:max="curRecord.is_attention"></el-rate>
							<span v-else>无</span>
						</el-descriptions-item>
					</el-descriptions>
				</div>
			</div>
<!-- 			<h4>病史</h4>
			<el-table :data="curRecord.disease" :header-cell-style="{background: '#fafafa'}" size="mini" style="width: 100%">
				<el-table-column label="病名" prop="name"></el-table-column>
				<el-table-column label="患病日期" prop="time"></el-table-column>
				<el-table-column label="备注" prop="remark"></el-table-column>
			</el-table>
			<h4>用药记录</h4>
			<el-table :data="curRecord.medication" :header-cell-style="{background: '#fafafa'}" size="mini"
				style="width: 100%">
				<el-table-column label="药物名称" prop="name"></el-table-column>
				<el-table-column label="剂量" prop="num"></el-table-column>
				<el-table-column label="备注" prop="remark"></el-table-column>
			</el-table>
			<h4>住院记录</h4>
			<el-table :data="curRecord.hospital" :header-cell-style="{background: '#fafafa'}" size="mini" style="width: 100%">
				<el-table-column label="住院日期" prop="hospitalized_time"></el-table-column>
				<el-table-column label="医院" prop="hospital"></el-table-column>
				<el-table-column label="科室" prop="department"></el-table-column>
				<el-table-column label="出院日期" prop="discharge_time"></el-table-column>
			</el-table>
			<h4>就诊记录</h4>
			<el-table :data="curRecord.out_patient" :header-cell-style="{background: '#fafafa'}" size="mini"
				style="width: 100%">
				<el-table-column label="就诊日期" prop="clinic_time"></el-table-column>
				<el-table-column label="记录" prop="clinic_record"></el-table-column>
				<el-table-column label="医生建议" prop="doctor_suggest"></el-table-column>
				<el-table-column label="备注" prop="remark"></el-table-column>
			</el-table> -->
		</el-card>

		<!-- 修改用户照片的弹窗 -->
		<el-dialog title="修改照片" :visible.sync="photoDialog" @close="closePhotoDialog" width="30%">
			<el-upload :action="UPLOAD_URL" :file-list="photoFileList" list-type="picture-card" :limit="1"
				:before-upload="handleBeforeUpload" :on-success="handlePhotoSuccess" :on-remove="handlePhotoRemove"
				:on-exceed="handlePhotoExceed">
				<i class="el-icon-plus"></i>
				<div slot="tip">只能上传jpg/png文件</div>
			</el-upload>
			<span slot="footer">
				<el-button @click="closePhotoDialog">取消</el-button>
				<el-button @click="handleSubmitPhoto" :loading="submitting" type="primary">提交</el-button>
			</span>
		</el-dialog>

		<!-- 修改餐饮号的弹窗 -->
		<el-dialog title="修改餐饮号" :visible.sync="repastDialog" :close-on-click-modal="false" @close="repastDialog = false"
			width="500px">
			<el-input v-model="repastNumber" type="text" placeholder="请输入餐饮号" clearable></el-input>
			<span slot="footer">
				<el-button @click="repastDialog = false">取消</el-button>
				<el-button @click="handleSubmitRepast" :loading="submitting" type="primary">提交</el-button>
			</span>
		</el-dialog>

		<!-- 亲属管理的弹窗 -->
		<RelationDialog ref="relationDialog"></RelationDialog>

		<UserRecordFormDialog ref="userRecordForm" @save-success="handleSaveSuccess"></UserRecordFormDialog>
	</div>
</template>

<script>
	import RelationDialog from './RelationDialog.vue'
	import UserRecordFormDialog from './UserRecordFormDialog.vue'

	import {
		saveUserRecordAPI,
		getUserRecordDataAPI
	} from '@/api/user-record/record.js'

	import {
		liveStatusDict,
		sexDict,
	} from '@/utils/dict.js'
	import {
		UPLOAD_URL,
	} from '@/utils/constant.js'

	export default {
		name: 'UserBaseInfo',
		components: {
			RelationDialog,
			UserRecordFormDialog,
		},
		props: {
			userId: Number,
			tabName: String,
			houseData: {
				type: Array,
				default: function() {
					return []
				}
			}
		},
		data() {
			return {
				UPLOAD_URL,
				liveStatusDict,
				sexDict,

				submitting: false,
				curRecord: null,

				// 照片相关
				photoDialog: false,
				photoFileList: [],

				// 餐饮号修改
				repastDialog: false,
				// 餐饮号，该变量用于修改时的回显
				repastNumber: '',
			}
		},
		watch: {
			userId: {
				immediate: true,
				handler(newUserId) {
					this.getUserRecordData(newUserId)
				}
			},
			// tabName() {
			// 	this.getUserRecordData(this.userId)
			// }
		},
		methods: {
			// 用户编辑按钮
			handleRecordEdit() {
				this.$refs.userRecordForm.open({
					userInfo: this.curRecord,
					houseData: this.houseData
				})
			},
			// 编辑成功
			handleSaveSuccess() {
				// 获取最新数据
				this.getUserRecordData(this.userId)
				// 通知父级让父级重新获取下左侧列表的数据
				this.$emit('user-update')
			},
			// 修改照片按钮，触发时设置 photoFileList 进行回显
			handleUpdatePhoto() {
				this.photoDialog = true

				if (this.curRecord.photo) {
					this.photoFileList = [{
						url: this.curRecord.photo
					}]
				} else {
					this.photoFileList = []
				}
			},
			// 照片上传之前
			handleBeforeUpload(file) {
				if (file.type.indexOf('png') < 0 && file.type.indexOf('jpeg') < 0) {
					this.$message.error('照片格式错误！')
					return false
				}
			},
			// 照片上传成功
			handlePhotoSuccess(res, file, fileList) {
				if (res.code === 0) this.photoFileList = fileList
				else this.$message.error('照片上传失败！')
			},
			// 文件数量超出限制
			handlePhotoExceed() {
				this.$message.warning('请删掉原有头像后再进行尝试')
			},
			// 照片移除
			handlePhotoRemove() {
				this.photoFileList = []
			},
			// 修改照片弹窗的提交按钮
			handleSubmitPhoto() {
				this.submitting = true
				let photo = ''
				// 设置photo
				if (this.photoFileList.length) {
					photo = this.photoFileList[0].response.data.url
				}
				const copyRecord = JSON.parse(JSON.stringify(this.curRecord))
				copyRecord.photo = photo

				saveUserRecordAPI(copyRecord).then(res => {
					this.$message.success(res.msg)
					this.closePhotoDialog()
					this.$emit('user-update')
					this.getUserRecordData(this.userId)
				}).finally(() => this.submitting = false)
			},
			// 关闭修改照片的弹窗
			closePhotoDialog() {
				this.photoDialog = false
				this.photoFileList = []
			},
			// 修改餐饮号按钮
			handleUpdateRepast() {
				this.repastDialog = true
				this.repastNumber = this.curRecord.repast_no
			},
			// 亲属管理按钮
			handleRelationManage() {
				this.$refs.relationDialog.open(this.curRecord.bed_number)
			},
			// 修改餐饮号弹窗的提交按钮
			handleSubmitRepast() {
				this.submitting = true
				const copyRecord = JSON.parse(JSON.stringify(this.curRecord))
				copyRecord.repast_no = this.repastNumber

				saveUserRecordAPI(copyRecord).then(res => {
					this.repastDialog = false
					this.$message.success(res.msg)
					this.$emit('user-update')
					this.getUserRecordData(this.userId)
				}).finally(() => this.submitting = false)
			},

			getUserRecordData(userId) {
				getUserRecordDataAPI({
					page: 1,
					id: userId
				}).then(res => this.curRecord = res.data[0])
			}
		}
	}
</script>

<style scoped>
	.record-card_header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.record-desc {
		display: flex;
	}

	.record-desc_left {
		margin-right: 10px;
		text-align: center;
	}

	.record-desc_right {
		flex: 1;
	}
</style>