<!-- 个人的疫苗接种情况 -->
<template>
  <div>
    <div style="margin-bottom: 10px;">
      <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      <el-button @click="handerRefresh" size="small">刷新</el-button>
    </div>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="姓名" prop="member.realname"></el-table-column>
      <el-table-column label="疫苗名称" prop="type_name.type_name"></el-table-column>
      <el-table-column label="接种时间" prop="time"></el-table-column>
      <el-table-column label="接种方式" prop="way"></el-table-column>
      <el-table-column label="接种地点" prop="location"></el-table-column>
      <el-table-column label="接种针数" prop="num"></el-table-column>
      <el-table-column label="厂家" prop="manufacturers"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleContinue(row)" type="primary" class="margin-r-10">续针</el-link>
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm title="确定删除吗？" @confirm="handleDel(row.id)">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" @current-change="getVaccines">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="closeDialog"
      width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="疫苗种类" prop="name">
          <el-select v-model="form.name" @change="handleTypeChange" class="el-input_inner--rewrite">
            <el-option v-for="item in vaccineCategories" :key="item.id" :label="item.type_name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- 展示接种记录 -->
        <div v-if="vaccineHistories.length" class="text-center">
          <h4>接种记录</h4>
          <p v-for="item in vaccineHistories" :key="item.id">
            <span class="font-b">针数：{{ item.num }}, </span>
            <span> 接种时间：{{ item.time }}</span>
          </p>
        </div>
        <el-form-item label="接种时间" prop="time">
          <el-date-picker v-model="form.time" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"
            class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="接种方式" prop="way">
          <el-input v-model="form.way"></el-input>
        </el-form-item>
        <el-form-item label="接种地点" prop="location">
          <el-input v-model="form.location"></el-input>
        </el-form-item>
        <el-form-item label="接种针数" prop="num">
          <el-input-number v-model="form.num" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="厂家" prop="manufacturers">
          <el-input v-model="form.manufacturers"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getVaccinesAPI,
    saveVaccineAPI,
    delVaccineAPI,
    getAllVaccineCategoriesAPI
  } from '@/api/vaccine.js'

  export default {
    name: 'PersonalVaccine',
    props: {
      userId: Number,
      tabName: String,
    },
    data() {
      return {
        vaccineCategories: [],

        queryForm: {
          realname: '',
          date_range: '',
          adminer: '',
          page: 1,
          page_size: 10,
          member_id: '',
          name: '',
        },

        dialogTitle: '',
        showDialog: false,
        submitting: false,
        form: {
          member_id: '',
          name: '',
          time: '',
          location: '',
          num: 1,
          way: '',
          remark: '',
          manufacturers: ''
        },
        rules: {
          name: [{
            required: true,
            message: '疫苗种类不能为空',
            trigger: 'change'
          }],
          time: [{
            required: true,
            message: '接种时间不能为空',
            trigger: 'change'
          }],
          location: [{
            required: true,
            message: '接种地点不能为空',
            trigger: 'blur'
          }],
          num: [{
            required: true,
            message: '接种针数不能为空',
            trigger: 'change'
          }],
          way: [{
            required: true,
            message: '接种方式不能为空',
            trigger: 'blur'
          }]
        },

        tableData: [],
        total: 0,
        tableLoading: false,
        curRowId: '',

        vaccineHistories: []
      }
    },
    watch: {
      // 如果父级 userId 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      userId: {
        immediate: true,
        handler() {
          if (this.tabName === 'PersonalVaccine') {
            this.queryForm.member_id = this.userId
            this.form.member_id = this.userId

            this.getVaccines()
          }
        }
      },
      // 如果父级 tabName 改变了，并且父级当前的 tabname 等于本组件的名字，就获取最新下数据
      tabName(newVal) {
        if (newVal === 'PersonalVaccine') {
          this.queryForm.member_id = this.userId
          this.form.member_id = this.userId
          this.getVaccines()
        }
      }
    },
    created() {
      getAllVaccineCategoriesAPI().then(res => this.vaccineCategories = res)
    },
    methods: {
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      handerRefresh() {
        this.getVaccines()
        getAllVaccineCategoriesAPI().then(res => this.vaccineCategories = res)
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.vaccineHistories = []
        this.showDialog = false
      },
      // 疫苗种类改变
      handleTypeChange(e) {
        this.getVaccinesByType({
          member_id: this.userId,
          name: e,
          page: 1
        })
      },
      // 续针
      handleContinue(row) {
        this.showDialog = true
        this.dialogTitle = '新增'
        const copyRow = JSON.parse(JSON.stringify(row))
        // 回显
        for (let key in this.form) {
          if (key == 'member_id' || key == 'num' || key == 'time') {
            continue
          }
          this.form[key] = copyRow[key]
        }
        this.form.num = parseInt(copyRow.num) + 1
      },
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowId = row.id
        const copyRow = JSON.parse(JSON.stringify(row))
        // 回显
        for (let key in this.form) {
          this.form[key] = copyRow[key]
        }
      },
      handleDel(id) {
        delVaccineAPI({
          id,
        }).then(res => {
          this.$message.success(res.msg)
          this.getVaccines()
        })
      },
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = Object.assign({}, this.form)

          if (this.dialogTitle == '编辑') copyForm.id = this.curRowId

          saveVaccineAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getVaccines()
          }).finally(() => this.submitting = false)
        })
      },

      getVaccines() {
        this.tableLoading = true

        getVaccinesAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.tableLoading = false
        })
      },
      getVaccinesByType(query) {
        getVaccinesAPI(query).then(res => this.vaccineHistories = res.data)
      }
    }
  }
</script>

<style>
</style>