// 照护服务
import request from '@/utils/util.js'

// 新增
export function addCareDataAPI(data) {
	return request.post('/tend', data)
}

// 修改
export function updateCareDataAPI(id, data) {
	return request.put(`/tend/${id}`, data)
}

// 列表
export function getCareDataAPI(data) {
	return request.get('/tend', data)
}

// 删除
export function delCareDataAPI(id) {
	return request.del(`/tend/${id}`)
}
