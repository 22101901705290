var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.handleAdd}},[_vm._v("新增")]),_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.getMedications}},[_vm._v("刷新")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":"","header-cell-style":{background:'#E4E7ED',color: '#606266'},"size":"small","max-height":"500px"}},[_c('el-table-column',{attrs:{"label":"#","type":"index","width":"60"}}),_c('el-table-column',{attrs:{"label":"药物名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isEdit)?_c('el-input',{attrs:{"placeholder":"药物名称","size":"small"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}}):_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"剂量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isEdit)?_c('el-input',{attrs:{"placeholder":"剂量","size":"small"},model:{value:(row.num),callback:function ($$v) {_vm.$set(row, "num", $$v)},expression:"row.num"}}):_c('span',[_vm._v(_vm._s(row.num))])]}}])}),_c('el-table-column',{attrs:{"label":"备注"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isEdit)?_c('el-input',{attrs:{"placeholder":"备注","size":"small"},model:{value:(row.remark),callback:function ($$v) {_vm.$set(row, "remark", $$v)},expression:"row.remark"}}):_c('span',[_vm._v(_vm._s(row.remark))])]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(row.isEdit)?_c('el-link',{staticClass:"margin-r-10",attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleRowSubmit($index)}}},[_vm._v("保存")]):_vm._e(),(row.id && !row.isEdit)?_c('el-link',{staticClass:"margin-r-10",attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleEdit(row)}}},[_vm._v("编辑")]):_vm._e(),_c('el-popconfirm',{attrs:{"title":"确定删除吗？"},on:{"confirm":function($event){return _vm.handleDel(row, $index)}}},[_c('el-link',{attrs:{"slot":"reference","type":"danger"},slot:"reference"},[_vm._v("删除")])],1)]}}])})],1),_c('div',{staticClass:"margin-t-10 flex"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next, total, jumper","total":_vm.total,"current-page":_vm.queryForm.page},on:{"update:currentPage":function($event){return _vm.$set(_vm.queryForm, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.queryForm, "page", $event)},"current-change":_vm.getMedications}}),_c('el-button',{attrs:{"size":"small"}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }